import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { BlogRoutes } from "../../../../core/constants/routes.constants";
import { generateArray } from "../../../../utils/helpers/array.helpers";
import {
  ABOUT_IT_GALAXY,
  COMPANY_ARRAY,
  CONTACT_ARRAY,
  HIRE_FREELANCERS_ARRAY,
} from "./FooterContent.constants";
import { getSocialMediaIcon } from "./FooterContent.helpers";
import {
  DividerStyle,
  RootStyle,
  StackStyle,
  TextStyle,
  TypographyStyle,
} from "./FooterContent.style";

function FooterContent() {
  const intl = useIntl();
  const isMobile = window.innerWidth <= 768;

  const TermOrPrivacy = () => (
    <StackStyle>
      <TypographyStyle
        style={{
          lineHeight: "20px",
          letterSpacing: "0.15px",
          marginBottom: 0,
          display: "flex",
          gap: "8px",
        }}
      >
        <Link
          to="/termsofservice"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          {intl.formatMessage({ id: "footer.terms.service" })}
        </Link>
        •
        <Link
          to="/useragreement"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          {intl.formatMessage({ id: "footer.terms.agreement" })}
        </Link>
        •
        <Link
          to="/privacypolicy"
          style={{ color: "inherit", textDecoration: "none" }}
        >
          {intl.formatMessage({ id: "footer.terms.privacy" })}
        </Link>
      </TypographyStyle>
    </StackStyle>
  );

  const SocialMedia = () => (
    <StackStyle
      direction={"row"}
      spacing={1.5}
      style={{ alignItems: "center", marginLeft: "30px" }}
    >
      {generateArray(7).map((value) => (
        <div key={value} style={{ cursor: "pointer" }}>
          {getSocialMediaIcon(value)}
        </div>
      ))}
    </StackStyle>
  );

  const TermsOrSocialMedia = () => (
    <StackStyle
      direction={isMobile ? "column" : "row"}
      style={{
        width: "100%",
        justifyContent: isMobile ? "center" : "space-between",
        alignItems: "center",
      }}
    >
      {isMobile ? (
          <SocialMedia />
      ) : (
        <>
          <TermOrPrivacy />
          <SocialMedia />
        </>
      )}
    </StackStyle>
  );

  return (
    <RootStyle>
      <DividerStyle />
      <StackStyle spacing={6} width={"100%"} style={{ paddingInline: "5%" }}>
        <StackStyle spacing={4.125}>
          {isMobile && (
            <StackStyle width={"100%"}>
              <TypographyStyle>
                {intl.formatMessage({ id: ABOUT_IT_GALAXY })}
              </TypographyStyle>
            </StackStyle>
          )}
          <StackStyle direction={"row"} width={"100%"} spacing={3}>
            {!isMobile && (
              <StackStyle width={"55%"}>
                <TypographyStyle>
                  {intl.formatMessage({ id: ABOUT_IT_GALAXY })}
                </TypographyStyle>
              </StackStyle>
            )}
            <StackStyle width={isMobile ? "33%" : "15%"} spacing={1.5}>
              <TextStyle>
                {intl.formatMessage({ id: "footer.sections.hire" })}
              </TextStyle>
              {HIRE_FREELANCERS_ARRAY.map((value, index) => (
                <TypographyStyle key={index} style={{ fontWeight: 500 }}>
                  {intl.formatMessage({ id: value })}
                </TypographyStyle>
              ))}
            </StackStyle>
            <StackStyle width={isMobile ? "33%" : "15%"} spacing={1.5}>
              <TextStyle>
                {intl.formatMessage({ id: "footer.sections.company" })}
              </TextStyle>
              {COMPANY_ARRAY.map((value, index) => (
                <TypographyStyle key={index} style={{ fontWeight: 500 }}>
                  {value === "footer.sections.company.blog" ? (
                    <Link
                      to={`/${BlogRoutes.blog}`}
                      style={{ color: "inherit", textDecoration: "none" }}
                    >
                      {intl.formatMessage({ id: value })}
                    </Link>
                  ) : (
                    intl.formatMessage({ id: value })
                  )}
                </TypographyStyle>
              ))}
            </StackStyle>
            <StackStyle width={isMobile ? "33%" : "15%"} spacing={1.5}>
              <TextStyle>
                {intl.formatMessage({ id: "footer.sections.contact" })}
              </TextStyle>
              {CONTACT_ARRAY.map((value, index) => (
                <TypographyStyle key={index} style={{ fontWeight: 500 }}>
                  {intl.formatMessage({ id: value })}
                </TypographyStyle>
              ))}
            </StackStyle>
          </StackStyle>
        </StackStyle>
        <StackStyle
          direction={isMobile ? "column" : "row"}
          style={{ alignItems: "center", justifyContent: "space-between" }}
        >
          <TermsOrSocialMedia />
        </StackStyle>
      </StackStyle>
    </RootStyle>
  );
}

export default FooterContent;
