import styled, { keyframes } from "styled-components";

export const RootStyle = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.spacing * 8}px;
  padding-top: 5%;
  align-items: center;
  padding-bottom: 10px;
  height: 70%;
  min-height: 700px;
  margin-top: 10px;
  transition: max-height 0.3s ease;

  @media (max-width: 1280px) {
    height: 30%;
    min-height: unset;
  }
  @media (max-width: 1024px) {
    height: 50vh;
    min-height: unset;
  }
`;


export const StyledSwitchButtonContainer = styled.div`
  position: absolute;
  top: 2%;
  z-index: 9;
 
  @media (max-width: 768px) {
    display: none;
  }
`;
export const StyleHeader = styled.div`
 display: flex;
 flex-direction: column;
 align-items: center;
 padding-top: 70px;
 width: 100% ;

  @media (max-width: 768px) {
   padding-top: 30px;
    width: 100% ;


  }
`;

export const StyleH1 = styled.h1`
  font-family: Inter, sans-serif;
  font-size: 2.5em; // Responsive font size
  text-align: center;
  width: 100%;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.7);
  margin-bottom: 5%;
    
  @media (max-width: 768px) {
  font-size: 20px; // Responsive font size
  margin-bottom: 8%;

  }

`;

export const StyleRadios = styled.div`
 display: flex;
 flex-direction: row;
 align-items: center; 
 color: white;
 margin-bottom: 20px; 
 flex-wrap: wrap;
 justify-content: center;
`;

export const StyleSpanRadio = styled.span`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  background-color: ${(props) => (props.selectedOption === props.option ? '#318CE7' : 'transparent')};
  margin-right: 8px;
  vertical-align: middle;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 10px;
    height: 10px;
  }
`;

export const StyleLabelRadio = styled.label`
  font-size: 22px;
  cursor: pointer;
  font-weight: 700;
  margin-left: 20px;
  padding-left: 5px;
  color: ${(props) => (props.selectedOption === props.option ? '#318CE7' : 'white')};
  
  @media (max-width: 768px) {
    font-size: 12px; // Responsive font size
  }
`;

export const StyleText = styled.p`
color: gray;
text-align: center;
font-size: 19px;
max-width: 800px;
lineHeight: 1.5;

  @media (max-width: 768px) {
    font-size: 12px;
    max-width: 800px;
    padding: 2px;

  }
`;



export const HomePageIconStyle = styled.img`
  position: absolute;
  top: -60px;
  left: -180px;
`;


export const TitleStyle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #14171f;
  margin: 0px;
`;

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = styled.span`
  font-size: 16px;
  margin: 0 10px;

  color: white;
`;

export const Toggle = styled.div`
  width: 47px;
  height: 25px;
  background-color: #e0e0e0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  position: relative;
  border: 0.5px solid #ffffff66;
  background: rgb(176, 181, 198);
  background: linear-gradient(
    160deg,
    rgba(176, 181, 198, 1) 0%,
    rgba(209, 222, 238, 1) 70%
  );
`;

export const ToggleButton = styled.div`
  width: 21px;
  height: 16px;
  /* background-color: #3f51b5; Change to blue */
  border-radius: 4px;
  border: 0.5px solid;
  background: rgb(228, 232, 237);
  background: linear-gradient(
    160deg,
    rgba(228, 232, 237, 1) 0%,
    rgba(227, 232, 238, 1) 100%
  );

  border-image-source: linear-gradient(
    120.48deg,
    rgba(255, 255, 255, 0.4) 2.6%,
    rgba(255, 255, 255, 0) 78.47%
  );
  transition: all 0.3s ease;
  position: absolute;
  box-shadow: 2.5px 2.5px 10px 0px #a6abbd;
  box-shadow: -2.5px -2.5px 10px 0px #fafbff66;
  left: ${(props) =>
    props.active ? "5px" : "calc(100% - 20px - 5px)"}; /* Move left or right */

  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;

export const StyledLine = styled.div`
  width: 3px;
  height: 80%;
  background-color: #586577;
  border-radius: 2px;
`;

export const TitleStyleHPEntreprise = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 800;
  line-height: 58px;
  text-align: left;
  color: ${(props) => (props.active === "FREELANCERS" ? "#FFFFFF" : "#14171f")};
  @media (max-width: 1024px) {
    font-size: 40px;
    padding-top: 100px;
    text-align: center;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

export const StyledInput = styled.input`
flex-grow: 1;
padding: 10px;
font-size: 16px;
border-radius: 5px;
border: 1px solid #ccc;
box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
margin-right: 10px;
background-color: white;

&:focus {
    outline: none;
    border-color: #007bff;
  }

 @media (max-width: 1280px) {
    font-size: 10px; // Responsive font size
  }
`;


const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const ContainerStyle = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-inline: 4%;
  opacity: ${({ index, currentIndex }) => (index === currentIndex ? 1 : 0)};
  transition: visibility 0s 0.01s, opacity 0.1s ease, transform 0.3s ease;
  visibility: ${({ isVisible }) => (isVisible ? "visible" : "hidden")};
  z-index: ${({ zIndex }) => zIndex};

  @media (max-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }
`;

export const StackStyle = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : "column")};
  gap: ${(props) => props.spacing * 8}px;
  width: ${(props) => props.width};

  @media (max-width: 1280px) {
    top: ${(props) => props.top} !important;
  }

  @media (max-width: 1024px) {
    width: 100% !important;
    align-items: center;
    text-align: center;

    &:first-child {
      margin-bottom: 20px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column; // Stack items vertically on smaller screens
    gap: ${(props) => props.spacing * 4}px; // Reduce gap on smaller screens
  }

  @media (max-width: 480px) {
    display: none;
    gap: ${(props) =>
      props.spacing * 2}px; // Further reduce gap on very small screens
  }
`;

export const ImageStyle = styled.img`

  object-fit: cover;
  border-radius: 10px;
  align-self: end;
  max-width: 800px;
  max-height: 670px;
  position: absolute;
  padding-top: 13%;
  margin-left: 0; // Corrected the typo, but may not be needed if using 'right'

  @media (max-width: 1440px) {
    padding-top: 20%;
  }

  @media (max-width: 1300px) {
    display: none; // Hide image on tablets
  }

  @media (max-width: 768px) {
    width: ${(props) => !props.hide && "350px"}; // Set width for small screens
    height: ${(props) =>
      !props.hide && "300px"}; // Set height for small screens
  }

  @media (max-width: 480px) {
    width: ${(props) =>
      !props.hide && "250px"}; // Further adjust size for mobile
    height: ${(props) => !props.hide && "200px"};
    border-radius: 5px; // Smaller border radius for mobile
  }
`;



export const BoxStyle = styled.div`
  display: flex;
  padding: 5px 8px 5px 8px;
  border-radius: ${(props) => props.radius};
  background: ${(props) => props.background};
  align-items: center;
  justify-content: center;

  p {
    font-family: Inter, sans-serif;
    font-size: 10px;
    font-weight: 800;
    line-height: 16px;
    text-align: center;
    color: ${(props) => props.color};
    margin-bottom: 0px;
  }
`;

export const TypographyStyle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 10px;
  font-weight: 800;
  line-height: 16px;
  text-align: left;
  color: #2684ff;
  margin-bottom: 0px;
  @media (max-width: 768px) {
    font-size: 8px;
  }
`;

export const ImageBackground = styled.div`
  align-self: center;
  width: 440px;
  height: 100%;
  border-radius: 1000px 1000px 1000px 10px;
  background: #2684ff;
  box-shadow: 10px 10px 18px 0px #00000026;
  @media (max-width: 1280px) {
    display: none;
  }
`;

export const StyledHomeContentContainer = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`;

export const ButtonsContainer = styled.div`
  position: absolute;
  bottom: 220px;
  left: 80px;
  display: flex;
  gap: 1rem;
  z-index: 1000;

  @media (max-width: 1280px) {
    bottom: -100px;
  }

  @media (max-width: 1024px) {
    position: relative;
    bottom: 100px;
    left: auto;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
    padding: 0 20px;
    /* flex-direction: column;
    align-items: center; */
  }
  @media (max-width: 480px) {
    bottom: 0;
  }
`;

export const ButtonStyle = styled.button`
  width: 320px;
  height: 75px;
  padding: 15px 29px;
  border-radius: 5px;
  font-family: Inter, sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24.2px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1024px) {
    width: 100%;
    height: 60px;
    font-size: 18px;
  }

  /* Default styling for "Get Started" button */
  ${(props) =>
    !props.isServices &&
    `
    background: ${props.active === "FREELANCERS" ? "#FFFFFF" : "#14171f"};
    color: ${props.active === "FREELANCERS" ? "#14171f" : "#FFFFFF"};
    border: none;
  `}

  /* Special styling for Services button */
  ${(props) =>
    props.isServices &&
    `
    background: ${props.active === "FREELANCERS" ? "black" : "white"};
    border: 1px solid ${props.active === "FREELANCERS" ? "white" : "black"};
    color: ${props.active === "FREELANCERS" ? "white" : "black"};
  `}

  &:hover {
    transform: translateY(-2px);
  }

  img {
    margin-left: 50px;
    object-fit: contain;

    /* Make white arrow bigger */
    ${(props) =>
      ((props.active === "FREELANCERS" && props.isServices) ||
        (props.active !== "FREELANCERS" && !props.isServices)) &&
      `
      width: 48px !important;
      height: 48px !important;
    `}

    /* Keep black arrow at original size */
    ${(props) =>
      ((props.active === "FREELANCERS" && !props.isServices) ||
        (props.active !== "FREELANCERS" && props.isServices)) &&
      `
      width: 24px !important;
      height: 24px !important;
    `}

    @media (max-width: 1024px) {
      width: 20px !important;
      height: 20px !important;
      margin-left: 20px;
    }
  }
`;

export const ButtonText = styled.span`
  text-align: center;
  font-size: 25px;

  @media (max-width: 480px) {
    font-size: 15px;
  }
`;

