import styled from "styled-components";

export const ChatbotContainer = styled.span`
  margin-bottom: 20px;
  display: inline-block;
`;

export const ChatbotMessage = styled.span`
  display: inline-block;
  padding: 10px;
  border-radius: 10px;
  background-color: #f5f5f5;
  margin-left: 10px;
`;
