import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import SideBar from "../../../components/SideBar/sideBar.jsx";
import {
  getAccessToken,
  getIsloggedFromLocalStorage,
} from "../../../core/helpers/storage.js";
import {
  StyledITgalaxyServicesContainer,
  Styleddashboard,
} from "./ChatEntreprise.style.js";
import ListEntrepriseContainer from "./ListEntrepriseContainer/ListEntrepriseContainer.jsx";
import ListServicesContainer from "./ListServicesContainer/ListServicesContainer.jsx";
import ThemeSwitcher from "./ThemeSwitcher.jsx";
import { Helmet } from "react-helmet"; // Import Helmet for SEO
import MobileDashboard from "components/MobileDashboard/MobileDashboard.jsx";

const ChatEntreprise = () => {
  const [theme, setTheme] = useState("light");
  const [usernameChat, setUsernameChat] = useState("");
  const [idEntreprise, setIdEntreprise] = useState("");
  const [chatId, setChatId] = useState("");
  const [role, setRole] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState("");

  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };
  
  const isMobile = window.innerWidth <= 768;

  
  useEffect(() => {
    const fetchToken = async () => {
      const token = await getAccessToken();
      const entrepriseToken = token ? jwtDecode(token) : null;
      if (entrepriseToken) {
        const id = entrepriseToken.id.toString();
        const usernamechat = entrepriseToken.usernamechat.toString();
        const Idchat = entrepriseToken.chatid.toString();
        const role = entrepriseToken.role.toString();
        const isLoggedIn = getIsloggedFromLocalStorage();
        setUsernameChat(usernamechat);
        setIdEntreprise(id);
        setChatId(Idchat);
        setIsLoggedIn(isLoggedIn);
        setRole(role);
      }
    };
    fetchToken();
  }, []);

  return (
    <> 
    {!isMobile ? (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
        maxHeight: "100vh",
      }}
    >
      <Helmet>
        <title>Chat Entreprise - ITGalaxy</title>
        <meta name="description" content="Discutez avec les profils freelances seclectionnées pour vos projets et gérez vos communications efficacement." />
      </Helmet>
      
      <SideBar
        isLoggedIn={isLoggedIn}
        path={"/DashboardCompany"}
        role={role}
        id={idEntreprise}
      />
      <StyledITgalaxyServicesContainer theme={theme}>
        <Styleddashboard>
          <ListEntrepriseContainer
            idEntreprise={idEntreprise}
            usernameChat={usernameChat}
            chatId={chatId}
            theme={theme}
          />
          <ListServicesContainer theme={theme} />
        </Styleddashboard>
      </StyledITgalaxyServicesContainer>
    </div>
      ) : (
        <MobileDashboard />
      )}
     </>
  );
};

export default ChatEntreprise;