import styled from "styled-components";

const StyledInput = styled.input`
  width: 100%;
  padding: 8px 12px;
  border: 1px solid ${(props) => (props.error ? "red" : "#E0E0E0")};
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  transition: border-color 0.2s;
  background-color: white;

  &:focus {
    outline: none;
    border-color: #007bff;
  }

  &:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 4px;
  font-size: 14px;
  color: #333;
`;

const CustomTextInput = ({
  value,
  name,
  disabled,
  onChange,
  placeholder,
  defaultValue,
  error,
  label,
  id = name,
}) => {

  return (
    <InputContainer>
      <StyledInput
        type="text"
        id={id}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        name={name}
        onChange={onChange}
        disabled={disabled}
        error={error}
        aria-label={label || placeholder}
        aria-invalid={!!error}
      />
    </InputContainer>
  );
};

export default CustomTextInput;
