export const COMPANY_NEW = "Company New";
export const COMPANY_EXIST = "Company Exist";

export const COMPANY_NEW_ITEMS = [
  { id: 1, title: "Did you have already a company ?" },
  { id: 2, title: "Create a new company" },
  { id: 3, title: "Company Name" },
  { id: 4, title: "Company Address" },
  { id: 5, title: "International Bank Account Number" },
  { id: 6, title: "Identity" },
  { id: 7, title: "Payment" },
];
/*
export const COMPANY_EXIST_ITEMS = [
  { id: 1, title: "Did you have already a company ?" },
  { id: 2, title: "Company Name" },
  { id: 3, title: "Identity" },
  { id: 4, title: "Company Address" },
  { id: 5, title: "Company Identity" },
]; */
export const COMPANY_EXIST_ITEMS = [
  { id: 1, title: "Company Name" },
  { id: 2, title: "Identity" },
  { id: 3, title: "Company Address" },
  { id: 4, title: "Company Identity" },
]; 