import { getTokenFromLocalStorage, sendVerificationEmail } from "core/helpers/storage.js";
import { Background, Logo, StyleCard, StyleCardRed, AcceptProposalButton } from "./AfterSignup.style.js";
import { jwtDecode } from "jwt-decode";
import { useResetVerifyEmailMutation } from "../../redux/api/verifyEmail/verifyEmailApi.js";
import toast from "react-hot-toast";

const ItGalaxyMobile = `${process.env.REACT_APP_CDN_ITGALAXY}/assets/logo/ItGalaxy-LogoWhite.png`;

function AfterSignup() {
  const token = getTokenFromLocalStorage();
  const [resetVeriftoken] = useResetVerifyEmailMutation();
  
  // Decode the JWT token if it exists
  const decodeToken = token ? jwtDecode(token) : null;
  const courier = decodeToken ? decodeToken.email : null;
  const role = decodeToken ? decodeToken.role : null;
  const username = role === 'ENTREPRISE' 
    ? decodeToken.username 
    : (decodeToken.name + " " + decodeToken.first_name);

  const ResendEmailVerification = async () => {
    try {
      // Attempt to reset verification token
      const { data } = await resetVeriftoken({
        filterType: role,
        email: courier
      });

      // Check if new token exists and send verification email
      if (data && data.token) {
        const newToken = jwtDecode(data.token);
        const newVerifToken = newToken.verificationToken;

        await sendVerificationEmail(
          username,
          courier,
          role,
          newVerifToken,
          "dashboard"
        );  
        toast.success("Verification email resent successfully!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

      } else {
        alert("Failed to retrieve a new verification token."); // Error handling
      }
    } catch (error) {
      console.error("Error during email verification token reset:", error);
      alert("An error occurred while resending the verification email. Please try again later."); // Error handling
    }
  };

  return (
    <Background>
      <Logo src={ItGalaxyMobile} alt="It Galaxy Logo" />

      <StyleCard>
        Welcome Aboard,<br />
        Thank you for joining our community!<br />
        Please check your email inbox for a confirmation message. Click the link inside to validate your account and unlock all the amazing features we offer.     
     </StyleCard>
    
     <StyleCardRed>
      Did not receive the email? Click the button below to resend it.
     </StyleCardRed>

      <AcceptProposalButton onClick={ResendEmailVerification}>
        Resend Verification Email
      </AcceptProposalButton>
    </Background>
  );
}

export default AfterSignup;