import { InputTypes } from "../Inputs/input.type";

export const formConfig = {
  title: {
    inputType: InputTypes.TEXT,
    fieldName: "title",
    placeholder: "Seach for Jobs",
    row: 2,
  },
};
