export const ABOUT_IT_GALAXY = "footer.about";

export const HIRE_FREELANCERS_ARRAY = [
  "footer.sections.hire.explore",
  "footer.sections.hire.top",
  "footer.sections.hire.design",
  "footer.sections.hire.engineering",
  "footer.sections.hire.marketing",
];

export const COMPANY_ARRAY = [
  "footer.sections.company.blog",
  "footer.sections.company.mission",
  "footer.sections.company.careers",
  "footer.sections.company.terms",
  "footer.sections.company.privacy",
  "footer.sections.company.cookie",
];

export const CONTACT_ARRAY = [
  "footer.sections.contact.demo",
  "footer.sections.contact.sales",
  "footer.sections.contact.support",
];
