import RenderStars from "components/RenderStars/RenderStars";
import { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TrainingRoutes } from "../../../../core/constants/routes.constants";
import useLoggedIn from "../../../../hooks/useLoggedIn";
import { getURL } from "../../../../redux/api/uploads/uploadSlice";
import Register from "../../../Authentification/modals/register";
import { StackStyle } from "../Formations.style";
import {
  CompetenceArea,
  CompetenceLabel,
  CompetenceLabelLanguages,
  HeadingStyle,
  ImageBoxStyle,
  PriceStyle,
  RootStyle,
  StarsLine,
  StyledImage
} from "./FormationsCard.style";

const RightUpArrow = `${process.env.REACT_APP_CDN_ITGALAXY}/assets/IconITgalaxy/arrow-up-right.svg`;

function FormationsCard({
  formation,
  animateDirection,
  category,
  onClick,
  useStaticImage = false,
  formationType,
}) {
  const navigate = useNavigate();
  const [openModalRegister, setOpenModalRegister] = useState(false);
  const isLoggedIn = useLoggedIn();

  const intl = useIntl();
  const {
    id,
    type,
    titleId,
    title,
    descriptionId,
    description,
    rising_star_global,
    languages,
    price,
    userId,
    skills,
    image,
    user,
    details,
    comments
  } = formation;

  const formationTitle = formationType === "HOME_PAGE" && titleId
    ? intl.formatMessage({ id: titleId })
    : title || type;

  const dispatch = useDispatch();
  const [fileName, setFileName] = useState(useStaticImage ? image : null);

  useEffect(() => {
    const getUrl = async (path) => {
      try {
        if (formationType === "HOME_PAGE") {
          const imageUrl = `${process.env.REACT_APP_URL_BUCKET}assets/formations/image_formation_${formation.id}.webp`;
          setFileName(imageUrl);
          return;
        }

        let url;
        if (type === "VIDEOSTRAINING" || type === "APPLICATION") {

          url = await dispatch(
            getURL({
              location: `${path}/${id}/image`,
            })
          );

        } else {
          url = await dispatch(
            getURL({
              location: `${path}`,
            })
          );
        }

        if (url.Contents && url.Contents.length > 0 && url.Contents[0].Key) {
          setFileName(process.env.REACT_APP_URL_BUCKET + url.Contents[0].Key);
        }
      } catch (error) {
        console.error("Error in getUrl:", error);
      }
    };

    let path;
    if (type === "APPLICATION") {
      path = "products/applications";
    } else if (formationType === "HOME_PAGE") {
      path = "assets/formations";
    } else if (type === "MARKETPLACE" || type === "MARKETPLACEPRODUCT") {
      if (formationTitle === "Server Ubuntu" || (formationTitle === "SERVER" && details?.OS === "ubuntu")) {
        path = "products/marketplace/server/ubuntu/";
      } else if (formationTitle === "Server Debian" || (formationTitle === "SERVER" && details?.OS === "debian")) {
        path = "products/marketplace/server/debian/";
      } else if (formationTitle === "Server RedHat" || (formationTitle === "SERVER" && details?.OS === "redhat")) {
        path = "products/marketplace/server/redhat/";
      } else if (formationTitle === "Database Mongodb" || (formationTitle === "DATABASE" && details?.TYPE === "mongodb")) {
        path = "products/marketplace/database/mongodb/";
      } else if (formationTitle === "Database Mysql" || (formationTitle === "DATABASE" && details?.TYPE === "mysql")) {
        path = "products/marketplace/database/mysql/";
      } else if (formationTitle === "Database Postgres" || (formationTitle === "DATABASE" && details?.TYPE === "postgres")) {
        path = "products/marketplace/database/postgres/";
      }
    } else if (type === "VIDEOSTRAINING") {
      path = "products/videos_trainings";
    }

    getUrl(path);
  }, [useStaticImage, dispatch, formation.id, id, formationTitle, type, formationType]);

  // Shorten formationTitle if longer than 20 characters
  const shortenedTitle = formationTitle ;
  

  const handleCardClick = useCallback(() => {
    if (formationType === "HOME_PAGE") {
      if (isLoggedIn) {
        navigate(`/${TrainingRoutes.videosTrainings}`);
      } else {
        setOpenModalRegister(true);
      }
    } else {
      onClick();
    }
  }, [formationType, isLoggedIn, navigate, onClick]);

  const handleModalRegister = useCallback(() => {
    setOpenModalRegister(!openModalRegister);
  }, [openModalRegister]);

  return (
    <>
      <RootStyle animateDirection={animateDirection} onClick={handleCardClick}>
        <ImageBoxStyle>
          <StyledImage
            src={fileName}
            alt={shortenedTitle}
          />
        </ImageBoxStyle>
        <StackStyle spacing={2.3}>
          <StackStyle spacing={1.5}>
            <StackStyle direction={"row"} style={{ justifyContent: "space-between" }}>
              <HeadingStyle>{shortenedTitle}</HeadingStyle>
              <img src={RightUpArrow} alt="right-up-arrow" />
            </StackStyle>
          </StackStyle>
          {type === "MARKETPLACE" ? (
            <StarsLine>
              <RenderStars stars={rising_star_global} nbr_comments={0} />
              <PriceStyle>{"As you use"}</PriceStyle>
            </StarsLine>
          ) : type === "MARKETPLACEPRODUCT" ? (
            <StarsLine>
              <RenderStars stars={rising_star_global} nbr_comments={0}/>
              <PriceStyle>
                {price} {"EURO"}
              </PriceStyle>
            </StarsLine>
          ) : (
            <StarsLine>
              <RenderStars stars={rising_star_global} nbr_comments={comments.length} />
              <PriceStyle>
                {price} {"EURO"}
              </PriceStyle>
            </StarsLine>
          )}
          <CompetenceArea>

            {skills?.length > 0 ? (
              skills.map((c, index) => (
                <CompetenceLabel key={index}>{c}</CompetenceLabel>
              ))
            ) : (
              <CompetenceLabel></CompetenceLabel>
            )}
          </CompetenceArea>
          {languages && (
            <CompetenceLabelLanguages>{languages}</CompetenceLabelLanguages>)}

        </StackStyle>
      </RootStyle>

      <Register
        openModalRegister={openModalRegister}
        setOpenModalRegister={setOpenModalRegister}
        handleModalRegister={handleModalRegister}
        switchBetweenModals={false}
        proxy={"dashboard"}
      />
    </>
  );
}

FormationsCard.defaultProps = {
  useStaticImage: false,
  onClick: () => { },
};

export default FormationsCard;
