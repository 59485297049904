export const countryLengthMapData = [
  { id: 1, title: "CN", LENGHT: 22, currency: "CNY", label: "China" },
  { id: 2, title: "IN", LENGHT: 20, currency: "INR", label: "India" },
  { id: 3, title: "US", LENGHT: 22, currency: "USD", label: "United States" },
  { id: 4, title: "ID", LENGHT: 16, currency: "IDR", label: "Indonesia" },
  { id: 5, title: "PK", LENGHT: 24, currency: "PKR", label: "Pakistan" },
  { id: 6, title: "BR", LENGHT: 29, currency: "BRL", label: "Brazil" },
  { id: 7, title: "NG", LENGHT: 10, currency: "NGN", label: "Nigeria" },
  { id: 8, title: "BD", LENGHT: 28, currency: "BDT", label: "Bangladesh" },
  { id: 9, title: "RU", LENGHT: 33, currency: "RUB", label: "Russia" },
  { id: 10, title: "MX", LENGHT: 18, currency: "MXN", label: "Mexico" },
  { id: 11, title: "JP", LENGHT: 17, currency: "JPY", label: "Japan" },
  { id: 12, title: "ET", LENGHT: 13, currency: "ETB", label: "Ethiopia" },
  { id: 13, title: "PH", LENGHT: 22, currency: "PHP", label: "Philippines" },
  { id: 14, title: "EG", LENGHT: 29, currency: "EGP", label: "Egypt" },
  { id: 15, title: "VN", LENGHT: 28, currency: "VND", label: "Vietnam" },
  { id: 16, title: "CD", LENGHT: 28, currency: "CDF", label: "DR Congo" },
  { id: 17, title: "TR", LENGHT: 26, currency: "TRY", label: "Turkey" },
  { id: 18, title: "IR", LENGHT: 22, currency: "IRR", label: "Iran" },
  { id: 19, title: "DE", LENGHT: 22, currency: "EUR", label: "Germany" },
  { id: 20, title: "TH", LENGHT: 15, currency: "THB", label: "Thailand" },
  { id: 21, title: "GB", LENGHT: 22, currency: "GBP", label: "United Kingdom" },
  { id: 22, title: "FR", LENGHT: 27, currency: "EUR", label: "France" },
  { id: 23, title: "IT", LENGHT: 27, currency: "EUR", label: "Italy" },
  { id: 24, title: "TZ", LENGHT: 20, currency: "TZS", label: "Tanzania" },
  { id: 25, title: "ZA", LENGHT: 28, currency: "ZAR", label: "South Africa" },
  { id: 26, title: "MM", LENGHT: 18, currency: "MMK", label: "Myanmar" },
  { id: 27, title: "KE", LENGHT: 20, currency: "KES", label: "Kenya" },
  { id: 28, title: "KR", LENGHT: 16, currency: "KRW", label: "South Korea" },
  { id: 29, title: "CO", LENGHT: 24, currency: "COP", label: "Colombia" },
  { id: 30, title: "ES", LENGHT: 24, currency: "EUR", label: "Spain" },
  { id: 31, title: "UG", LENGHT: 30, currency: "UGX", label: "Uganda" },
  { id: 32, title: "AR", LENGHT: 20, currency: "ARS", label: "Argentina" },
  { id: 33, title: "DZ", LENGHT: 20, currency: "DZD", label: "Algeria" },
  { id: 34, title: "SD", LENGHT: 18, currency: "SDG", label: "Sudan" },
  { id: 35, title: "UA", LENGHT: 29, currency: "UAH", label: "Ukraine" },
  { id: 36, title: "IQ", LENGHT: 23, currency: "IQD", label: "Iraq" },
  { id: 37, title: "AF", LENGHT: 24, currency: "AFN", label: "Afghanistan" },
  { id: 38, title: "PL", LENGHT: 28, currency: "PLN", label: "Poland" },
  { id: 39, title: "CA", LENGHT: 24, currency: "CAD", label: "Canada" },
  { id: 40, title: "MA", LENGHT: 24, currency: "MAD", label: "Morocco" },
  { id: 41, title: "SA", LENGHT: 18, currency: "SAR", label: "Saudi Arabia" },
  { id: 42, title: "UZ", LENGHT: 28, currency: "UZS", label: "Uzbekistan" },
  { id: 43, title: "PE", LENGHT: 20, currency: "PEN", label: "Peru" },
  { id: 44, title: "AO", LENGHT: 25, currency: "AOA", label: "Angola" },
  { id: 45, title: "MY", LENGHT: 20, currency: "MYR", label: "Malaysia" },
  { id: 46, title: "MZ", LENGHT: 21, currency: "MZN", label: "Mozambique" },
  { id: 47, title: "GH", LENGHT: 10, currency: "GHS", label: "Ghana" },
  { id: 48, title: "YE", LENGHT: 24, currency: "YER", label: "Yemen" },
  { id: 49, title: "NP", LENGHT: 29, currency: "NPR", label: "Nepal" },
  { id: 50, title: "VE", LENGHT: 20, currency: "VES", label: "Venezuela" }
];
