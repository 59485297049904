import React, { startTransition, useCallback,useState } from "react";
import { useIntl } from "react-intl";
import {
  Label,
  RootStyle,
  StyledLine,
  StyledSwitchButtonContainer,
  SwitchContainer,
  ImageStyle,
  StyledInput,
  Toggle,
  ToggleButton,
  StyleH1,
  StyleRadios,
  StyleLabelRadio,
  StyleSpanRadio,
  StyleText,
  StyleHeader
} from "./HomeSlider.style";
import { Search } from 'lucide-react';
const SecondFreeLancerSliderImagePng = `${process.env.REACT_APP_CDN_ITGALAXY}/assets/images-webp/sliderHome5.png`;

const HomeSliderContent = React.lazy(() =>
  import("./HomeSliderContent/HomeSliderContent")
);

const HomeSlider = ({ active, setActive }) => {
  const intl = useIntl();
  const [selectedOption, setSelectedOption] = useState("Profiles");
  const [searchTerm, setSearchTerm] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const getPlaceholderText = () => {
    switch (selectedOption) {
      case "Profiles":
        return "Cherchez des Profiles Freelances";
      case "Services et Cloud":
        return "Cherchez dans la marketplace ItGalaxy";
      default:
        return "cherchez...";
    }
  };

  const getText = () => {
    switch (selectedOption) {
      case "Profiles":
        return "Trouvez des freelances en les contactant directement ou en partageant votre projet avec notre communauté.";
      case "Services et Cloud":
        return "Réservez vos serveurs , bases de données et des services prêts à l'emploi, que vous pouvez également personnaliser selon les besoins de votre projet.";
      default:
        return "Search...";
    }
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === 'Enter') {
      switch (selectedOption) {
        case "Profiles":
          window.location.href = `/search/freelancers`;
          break;
        case "Services et Cloud":
          window.location.href = `/search/products`;
          break;
        default:
          break;
      }
    }
  };



  const toggleSwitch = useCallback(() => {
    startTransition(() => {
      setActive(active === "ENTREPRISES" ? "FREELANCERS" : "ENTREPRISES");
    });
  }, [active, setActive]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <RootStyle>
      <StyledSwitchButtonContainer>
        <SwitchContainer>
          <Label focus={active === "ENTREPRISES"} active={active}>
            {intl.formatMessage({ id: "business" })}
          </Label>
          <Toggle onClick={toggleSwitch}>
            <ToggleButton active={active === "ENTREPRISES"}>
              <StyledLine />
              <StyledLine />
              <StyledLine />
            </ToggleButton>
          </Toggle>
          <Label focus={active === "FREELANCERS"} active={active}>
            {intl.formatMessage({ id: "it_workers" })}
          </Label>
        </SwitchContainer>
      </StyledSwitchButtonContainer>
        {active === "FREELANCERS" ? (
            <HomeSliderContent/>
        ) : (
          <StyleHeader>
            <StyleH1>
              {"Identifiez les freelances et les services proposés pour réussir vos projets"}
            </StyleH1>
            
            <StyleRadios>
              {["Profiles", "Services et Cloud"].map(option => (
                <StyleLabelRadio
                  key={option}
                  selectedOption={selectedOption}
                  option={option}
                  onClick={() => setSelectedOption(option)}
                >
                  <input
                    type="radio"
                    value={option}
                    checked={selectedOption === option}
                    onChange={handleOptionChange}
                    style={{ display: 'none' }} // Hide the default radio button
                  />
                  <StyleSpanRadio
                    selectedOption={selectedOption}
                    option={option}
                  />
                  {option}
                </StyleLabelRadio>
              ))}
            </StyleRadios>
            
            <div
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '1000px',
              maxWidth: '90%',
              marginBottom: '30px',
              position: 'relative', // Ensure the container is positioned relative
            }}
          >
            <StyledInput
              type="text"
              placeholder={getPlaceholderText()}
              value={searchTerm}
              onKeyDown={handleEnterKeyPress} // Add this line
              onChange={handleSearchChange}
            />
            <Search
              style={{
                position: 'absolute',
                right: '15px', // Set the icon's right position
                color: 'black', // Color of the icon
                pointerEvents: 'none', // Ensure the icon doesn't interfere with input focus
              }}
            />
          </div>
        
            <StyleText>
              {getText()}
            </StyleText>
           <ImageStyle src={SecondFreeLancerSliderImagePng} />
          </StyleHeader>   
        )}
    </RootStyle>
  );
};

export default HomeSlider;