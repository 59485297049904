export const PRODUCT_SERVER = "Product Server";
export const PRODUCT_DATABASE = "Product Database";
export const PRODUCT_GITUB = "Product Github";

export const PRODUCT_SERVER_ITEMS = [
  { id: 1, title: "Server Capacity" },
  { id: 2, title: "Server Credentials" },
  { id: 3, title: "Server reservation duration" },
  { id: 4, title: "Payment" },
];

export const PRODUCT_DATABASE_ITEMS = [
  { id: 1, title: "Database Version" },
  { id: 2, title: "Database Capacity" },
  { id: 3, title: "Database Credentials" },
  { id: 4, title: "Database reservation duration" },
  { id: 5, title: "Payment" },
];
