import styled from "styled-components";

export const Background = styled.div`
 background:  black ;
 background-image: url(/static/media/homeBackground.3901b58aa5016f29c2f2.png);
 height: 100vh;
 width: 100%;
 color: white;
 background-size: contain;
`;

export const StyleCard = styled.p`
font-size: 30px; /* large text size */
font-family: 'Arial', sans-serif; /* Change this to a more beautiful font if desired */
/* border: 2px solid white;  border around the text */
margin-top: 50px;
padding: 20px; /* spacing inside the border */
text-align: center; /* center text */
`;
export const Logo = styled.img`
width: 408px;
padding-top: 50px;
height: 140px;
cursor: pointer;


`;

export const StyleCardRed = styled.p`
font-size: 30px; /* large text size */
font-family: 'Arial', sans-serif; /* Change this to a more beautiful font if desired */
/* border: 2px solid white;  border around the text */
padding: 20px; /* spacing inside the border */
text-align: center; /* center text */
color : Red ;
`;


export const AcceptProposalButton = styled.button`
  background: var(--Success-Success200, rgba(21, 176, 151, 1));
  color: black;
  margin-left: 45%;
  height: 80px;
  width: 300px;
  border-radius: 10px;
  font-weight: bold;
  font-size: 20px;
  justify-content: center;
  align-items: center;
  grap: 15px;
  &:hover {
    background: green; // Change background color on hover
    cursor: pointer; // Optional: Change cursor to pointer
    color: white;
  }
`;