import { getAccessToken } from "core/helpers/storage";
import { jwtDecode } from "jwt-decode";
import { lazy, Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RootStyle } from "./DashboardHome.style";


const Formations = lazy(() => import("./Formations/Formations"));
const Landing = lazy(() => import("./Landing/Landing"));
const LandingContent = lazy(() => import("./LandingContent/LandingContent"));
const FooterHome = lazy(() => import("./FooterHome/FooterHome"));

function DashboardHome({ active, isMobile }) {
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTokenAndNavigate = async () => {
      const token = await getAccessToken();
      if (token) {
        try {
          const decodeToken = jwtDecode(token);
          const typeUser = decodeToken.role;

          const routeMap = {
            CANDIDAT: "/dashboardCandidat",
            ENTREPRISE: "/DashboardCompany",
            RECRUTER: "/dashboardRecruter",
            ACCOUNTING: "/dashboardAccounting",
          };

          const route = routeMap[typeUser];
          if (route) {
            navigate(route, { replace: true });
          } else {
            console.warn("User role not recognized");
          }
        } catch (error) {
          console.error("Error decoding token:", error);
        }
      }
    };

    fetchTokenAndNavigate();
  }, [navigate]);

  return (
    <RootStyle spacing={12.5}>
      <Suspense fallback={<div>Loading...</div>}>
        <Formations />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <Landing />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <LandingContent />
      </Suspense>

      <Suspense fallback={<div>Loading...</div>}>
        <FooterHome />
      </Suspense>
    </RootStyle>
  );
}

export default DashboardHome;
