import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { IntlProvider } from "react-intl";
import { Router } from "./routes/routes";
import { messages, defaultLocale } from './locales';
import { LanguageProvider, useLanguage } from './core/contexts/LanguageContext';
import { BrowserRouter } from "react-router-dom";
import { useEffect, Suspense, lazy, memo } from "react";


const AppContent = memo(() => {

  return (
    <IntlProvider
      messages={messages['fr']}
      locale={'fr'}
      defaultLocale={defaultLocale}
      onError={(err) => console.error('IntlProvider error:', err)}
    >
      <div className="App content-desk" id="app">
        <BrowserRouter future={{ v7_startTransition: true }}>
          <Router />
        </BrowserRouter>
      </div>
    </IntlProvider>
  );
});

function App() {
  return (
    <LanguageProvider>
      <AppContent />
    </LanguageProvider>
  );
}

export default App;