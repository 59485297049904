export const categoryData = [
    { id: "0", title: "DEVELOPER BACKEND", value: "DEVELOPER_BACKEND", label: "DEVELOPER_BACKEND" },
    { id: "1", title: "DEVELOPER FRONTEND", value: "DEVELOPER_FRONTEND", label: "DEVELOPER_FRONTEND" },
    { id: "2", title: "DEVOPS ENGINEER", value: "DEVOPS", label: "DEVOPS" },
    { id: "3", title: "MOBILE DEVELOPER", value: "DEVELOPER_MOBILE", label: "DEVELOPER_MOBILE" },
    { id: "4", title: "DESIGNER", value: "DESIGNER", label: "DESIGNER" },
    { id: "5", title: "DATA SCIENTIST", value: "DATA_SCIENTIST", label: "DATA_SCIENTIST" },
    { id: "6", title: "DATA ENGINEER", value: "DATA_ENGINEER", label: "DATA_ENGINEER" },
    { id: "7", title: "SYSTEM ADMINISTRATOR", value: "SYSTEM_ADMINISTRATOR", label: "SYSTEM_ADMINISTRATOR" },
    { id: "8", title: "DATABASE ADMINISTRATOR", value: "DATABASE_ADMINISTRATOR", label: "DATABASE_ADMINISTRATOR" },
    { id: "9", title: "QA ENGINEER", value: "QA_ENGINEER", label: "QA_ENGINEER" },
    { id: "10", title: "CLOUD ARCHITECT", value: "CLOUD_ARCHITECT", label: "CLOUD_ARCHITECT" },
    { id: "11", title: "BLOCKCHAIN DEVELOPER", value: "BLOCKCHAIN_DEVELOPER", label: "BLOCKCHAIN_DEVELOPER" },
    { id: "12", title: "NETWORK ENGINEER", value: "NETWORK_ENGINEER", label: "NETWORK_ENGINEER" },
    { id: "13", title: "IT SUPPORT SPECIALIST", value: "IT_SUPPORT", label: "IT_SUPPORT" },
    { id: "14", title: "FULL STACK DEVELOPER", value: "FULL_STACK_DEVELOPER", label: "FULL_STACK_DEVELOPER" },
    { id: "15", title: "SYSTEMS ANALYST", value: "SYSTEMS_ANALYST", label: "SYSTEMS_ANALYST" },
    { id: "16", title: "IT PROJECT MANAGER", value: "IT_PROJECT_MANAGER", label: "IT_PROJECT_MANAGER" },
    { id: "17", title: "TECHNICAL WRITER", value: "TECHNICAL_WRITER", label: "TECHNICAL_WRITER" },
    { id: "18", title: "SOFTWARE ARCHITECT", value: "SOFTWARE_ARCHITECT", label: "SOFTWARE_ARCHITECT" },
    { id: "19", title: "IT CONSULTANT", value: "IT_CONSULTANT", label: "IT_CONSULTANT" },
    { id: "20", title: "WEB DEVELOPER", value: "WEB_DEVELOPER", label: "WEB_DEVELOPER" },
    { id: "21", title: "GRAPHIC DESIGNER", value: "GRAPHIC_DESIGNER", label: "GRAPHIC_DESIGNER" },
    { id: "22", title: "IT SECURITY SPECIALIST", value: "IT_SECURITY_SPECIALIST", label: "IT_SECURITY_SPECIALIST" },
    { id: "23", title: "IT SYSTEMS ENGINEER", value: "IT_SYSTEMS_ENGINEER", label: "IT_SYSTEMS_ENGINEER" },
    { id: "24", title: "APPLICATION SUPPORT", value: "APPLICATION_SUPPORT", label: "APPLICATION_SUPPORT" },
    { id: "25", title: "BI DEVELOPER", value: "BI_DEVELOPER", label: "BI_DEVELOPER" },
    { id: "26", title: "Game Developer", value: "GAME_DEVELOPER", label: "GAME_DEVELOPER" },
    { id: "27", title: "VERSION CONTROL SPECIALIST", value: "VERSION_CONTROL_SPECIALIST", label: "VERSION_CONTROL_SPECIALIST" },
    { id: "28", title: "DATA ANALYST", value: "DATA_ANALYST", label: "DATA_ANALYST" },
    { id: "29", title: "TECH SUPPORT SPECIALIST", value: "TECH_SUPPORT_SPECIALIST", label: "TECH_SUPPORT_SPECIALIST" },
    { id: "30", title: "INFORMATION SECURITY ANALYST", value: "INFORMATION_SECURITY_ANALYST", label: "INFORMATION_SECURITY_ANALYST" },
    { id: "31", title: "IT AUDITOR", value: "IT_AUDITOR", label: "IT_AUDITOR" },
    { id: "32", title: "IT TRAINER", value: "IT_TRAINER", label: "IT_TRAINER" },
    { id: "33", title: "SYSTEMS ENGINEER", value: "SYSTEMS_ENGINEER", label: "SYSTEMS_ENGINEER" },
    { id: "34", title: "APPLICATION DEVELOPER", value: "APPLICATION_DEVELOPER", label: "APPLICATION_DEVELOPER" },
    { id: "35", title: "IT OPERATIONS MANAGER", value: "IT_OPERATIONS_MANAGER", label: "IT_OPERATIONS_MANAGER" },
    { id: "36", title: "DATABASE DEVELOPER", value: "DATABASE_DEVELOPER", label: "DATABASE_DEVELOPER" },
    { id: "37", title: "BIG DATA ENGINEER", value: "BIG_DATA_ENGINEER", label: "BIG_DATA_ENGINEER" },
    { id: "38", title: "SCRIPTING DEVELOPER", value: "SCRIPTING_DEVELOPER", label: "SCRIPTING_DEVELOPER" },
    { id: "39", title: "SITE RELIABILITY ENGINEER", value: "SITE_RELIABILITY_ENGINEER", label: "SITE_RELIABILITY_ENGINEER" },
    { id: "40", title: "EXCEL DEVELOPER", value: "EXCEL_DEVELOPER", label: "EXCEL_DEVELOPER" },
    { id: "41", title: "CHATBOT DEVELOPER", value: "CHATBOT_DEVELOPER", label: "CHATBOT_DEVELOPER" },
    { id: "42", title: "QA MANAGER", value: "QA_MANAGER", label: "QA_MANAGER" },
    { id: "43", title: "USER EXPERIENCE RESEARCHER", value: "USER_EXPERIENCE_RESEARCHER", label: "USER_EXPERIENCE_RESEARCHER" },
    { id: "44", title: "IT STRATEGIST", value: "IT_STRATEGIST", label: "IT_STRATEGIST" },
    { id: "45", title: "ENTERPRISE ARCHITECT", value: "ENTERPRISE_ARCHITECT", label: "ENTERPRISE_ARCHITECT" },
    { id: "46", title: "IT COMPLIANCE SPECIALIST", value: "IT_COMPLIANCE_SPECIALIST", label: "IT_COMPLIANCE_SPECIALIST" },
    { id: "47", title: "SYSTEM TESTER", value: "SYSTEM_TESTER", label: "SYSTEM_TESTER" },
    { id: "48", title: "API DEVELOPER", value: "API_DEVELOPER", label: "API_DEVELOPER" },
    { id: "49", title: "CHIEF INFORMATION OFFICER (CIO)", value: "CIO", label: "CIO" },
    { id: "50", title: "CHIEF TECHNOLOGY OFFICER (CTO)", value: "CTO", label: "CTO" },
    { id: "51", title: "INFORMATION SYSTEMS MANAGER", value: "INFORMATION_SYSTEMS_MANAGER", label: "INFORMATION_SYSTEMS_MANAGER" },
    { id: "52", title: "DEVOPS CONSULTANT", value: "DEVOPS_CONSULTANT", label: "DEVOPS_CONSULTANT" },
    { id: "53", title: "TELECOM ENGINEER", value: "TELECOM_ENGINEER", label: "TELECOM_ENGINEER" },
    { id: "54", title: "IT RISK MANAGER", value: "IT_RISK_MANAGER", label: "IT_RISK_MANAGER" },
    { id: "55", title: "VIRTUALIZATION ENGINEER", value: "VIRTUALIZATION_ENGINEER", label: "VIRTUALIZATION_ENGINEER" },
    { id: "55", title: "EXPERT WORDPRESS", value: "EXPERT_WORDPRESS", label: "EXPERT_WORDPRESS" },
    { id: "55", title: "EXPERT SEO", value: "EXPERT_SEO", label: "EXPERT_SEO" },
    { id: "55", title: "EXPERT SHOPIFY", value: "EXPERT_SHOPIFY", label: "EXPERT_SHOPIFY" },

  ];

