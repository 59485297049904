import styled from "styled-components";

export const StyledIdentityInputsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: center;
`;

export const StyledIdentityInputsContainerFile = styled.div`
width: 100%;
`;