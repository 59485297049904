import ViewAccountingProfil from "./Profils/Accounting/ViewAccountingProfil";
import ViewCandidatProfil from "./Profils/Candidat/ViewCandidatProfil";
import ViewEntrepriseProfil from "./Profils/Entreprise/ViewEntrepriseProfil";
import ViewRecruterProfil from "./Profils/Recruter/ViewRecruterProfil";
import { BlocShowJobRecruter , BlocShowJobCandidat ,BlocShowJobEntreprise } from "components/ModalITgalaxy/ModalApply/ModalApply.style";

export default function EditProfil(props) {
  return (
    <>
        {props.role === "CANDIDAT" && (
          <>
            {props.editProfil ? (
              <BlocShowJobCandidat>
                <ViewCandidatProfil
                  data={props.userData}
                  editProfil={props.editProfil}
                  setDataUser={props.setDataUser}
                />
              </BlocShowJobCandidat>
            ) : (
              <ViewCandidatProfil
                data={props.userData}
                editProfil={props.editProfil}
                setDataUser={props.setDataUser}
              />
            )}
          </>
        )}


        {props.role === "ENTREPRISE" && (
          <>
            {props.editProfil ? (
              <BlocShowJobEntreprise>
                <ViewEntrepriseProfil
                  data={props.userData}
                  editProfil={props.editProfil}
                  setDataUser={props.setDataUser}
                />
              </BlocShowJobEntreprise>
            ) : (
              <ViewEntrepriseProfil
                data={props.userData}
                editProfil={props.editProfil}
                setDataUser={props.setDataUser}
              />
            )}
          </>
        )}
      {props.role === "RECRUTER" && (
        <BlocShowJobRecruter>
        <ViewRecruterProfil
          data={props.userData}
          editProfil={props.editProfil}
          setDataUser={props.setDataUser}
        />
        </BlocShowJobRecruter>

      )}
      {props.role === "ACCOUNTING" && (
        <ViewAccountingProfil
          data={props.userData}
          editProfil={props.editProfil}
          setDataUser={props.setDataUser}
        />
      )}
    </>
  );
}
