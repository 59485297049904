import RegisterAccounting from "components/Authentification/modals/registerAccounting";
import Profilfreelances from "components/ComponnentProfilItems/profilfreelances/profilfreelances";
import MarketplaceComponents from "components/DashboardItGalaxyMarketplaceComponnent/MarketplaceComponnents/MarketplaceComponnents";
import VideosTrainingsComponnents from "components/DashboardTrainingComponnents/TrainingsComponnents/VideosTrainingsComponnents/VideosTrainingsComponnents";
import Jobs from "components/Jobs/Jobs";
import Login from "components/Login/Login";
import ProductPurchased from "components/ProductPurchased/ProductPurchased";
import Register from "components/Register/Register";
import { useIntl } from "react-intl";
import ControllerAccounting from "shared/guards/ControllerAccounting";
import ControllerGuard from "shared/guards/ControllerGuard";
import AfterSignup from "../../components/AfterSignup/AfterSignup";
import ItGalaxyProductDatabase from "../../components/CreateItGalaxyProductsComponnent/ItGalaxyProductCreateDatabase/ItGalaxyProductCreateDatabase";
import ItGalaxyProductCreateServer from "../../components/CreateItGalaxyProductsComponnent/ItGalaxyProductCreateServer/ItGalaxyProductCreateServer";
import ItGalaxyProductDetail from "../../components/ItGalaxyProductDetail/ItGalaxyProductDetail";
import { JoinUs } from "../../components/JoinUs/JoinUs";
import ModalForgotPassword from "../../components/ModalITgalaxy/ModalForgotPassword/ModalForgotPassword";
import ModalResetPassword from "../../components/ModalITgalaxy/ModalResetPassword/ModalResetPassword";
import RecruitmentDash from "../../components/RecruitmentComponents/RecruitmentDash";
import ShareCandidat from "../../components/Sharing/shareCandidat";
import ShareJob from "../../components/Sharing/shareJobs";
import PrivacyPolicy from "../../components/TermsItGalaxy/PrivacyPolicy";
import TermsOfService from "../../components/TermsItGalaxy/TermsOfService";
import UserAgreement from "../../components/TermsItGalaxy/UserAgreement";
import VerifyEmail from "../../components/VerifyEmail/VerifyEmail";
import NotFound from "../../pages/404/notFound";
import Blog from "../../pages/Blog/Blog";
import BlogPost from "../../pages/Blog/components/BlogPost";
import ChatAccounting from "../../pages/Chat/ChatAccounting/ChatAccounting";
import ChatCandidat from "../../pages/Chat/ChatCandidat/ChatCandidat";
import ChatEntreprise from "../../pages/Chat/ChatEntreprise/ChatEntreprise";
import ChatRecruter from "../../pages/Chat/ChatRecruter/ChatRecruter";
import Company from "../../pages/Company/Company";
import DashboardHome from "../../pages/DashboardHomePage/DashboardHomePage";
import DashboardHomePageAccounting from "../../pages/DashboardHomePageAccounting/DashboardHomePageAccounting";
import DashboardITProducer from "../../pages/DashboardITProducer/DashboardITProducer";
import MyProductEdit from "../../pages/DashboardITProducer/MyProductEdit";
import DashboardTraining from "../../pages/DashboardTraining/DashboardTraining";
import ItGalaxyContactor from "../../pages/ItGalaxyContactor/ItGalaxyContactor";
import Marketplace from "../../pages/Marketplace/Marketplace";
import ItGalaxyProductPurchased from "../../pages/Marketplace/MarketplacePruchased/ItGalaxyProductPurchased/ItGalaxyProductPurchased";
import MyWorkingFolders from "../../pages/MyWorkingFolders/MyWorkingFolders";
import Profil from "../../pages/Profil/Profil";
import Projects from "../../pages/projects/projects";
import Recruitment from "../../pages/Recruitment/Recruitment";
import Wallet from "../../pages/Wallet/Wallet";
import ControllerCandidat from "../../shared/guards/ControllerCandidat";
import ControllerCompany from "../../shared/guards/ControllerCompany";
import ControllerRecruter from "../../shared/guards/ControllerRecruter";
import {
  AuthRoutes,
  BlogRoutes,
  DashboardAccountingRoutes,
  DashboardCompanyRoutes,
  DashboardFreelancerRoutes,
  DashboardRecruterRoutes,
  ItGalaxyMarketplaceRoutes,
  PublicRoutes,
  RootRoute,
  TrainingRoutes,
} from "../constants/routes.constants";

const businessIcon = `${process.env.REACT_APP_CDN_ITGALAXY}/assets/images-webp/business.webp`;
const individualIcon = `${process.env.REACT_APP_CDN_ITGALAXY}/assets/images-webp/individual.webp`;
const recruiterIcon = `${process.env.REACT_APP_CDN_ITGALAXY}/assets/images-webp/recruiter.webp`;

const JoinUsWithTranslations = () => {
  const intl = useIntl();

  return (
    <JoinUs
      items={[
        {
          id: 0,
          icon: businessIcon,
          label: intl.formatMessage({
            id: "join_us.user_types.business.label",
          }),
          description: intl.formatMessage({
            id: "join_us.user_types.business.description",
          }),
        },
        {
          id: 1,
          icon: individualIcon,
          label: intl.formatMessage({
            id: "join_us.user_types.individual.label",
          }),
          description: intl.formatMessage({
            id: "join_us.user_types.individual.description",
          }),
        },
        {
          id: 2,
          icon: recruiterIcon,
          label: intl.formatMessage({
            id: "join_us.user_types.recruiter.label",
          }),
          description: intl.formatMessage({
            id: "join_us.user_types.recruiter.description",
          }),
        },
      ]}
      title={"Hello ✨"}
      description={intl.formatMessage({ id: "join_us.welcome_message" })}
    />
  );
};

export const RoutesConfig = [
  {
    path: RootRoute,
    element: <DashboardHome />,
  },

  {
    path: AuthRoutes.verifyemail,
    element: <VerifyEmail />,
  },
  {
    path: AuthRoutes.resetpassword,
    element: <ModalResetPassword />,
  },

  {
    path: AuthRoutes.checkyouremailplz,
    element: <AfterSignup />,
  },

  {
    path: AuthRoutes.UserAgreement,
    element: <UserAgreement />,
  },

  {
    path: AuthRoutes.PrivacyPolicy,
    element: <PrivacyPolicy />,
  },

  {
    path: AuthRoutes.TermsOfService,
    element: <TermsOfService />,
  },

  {
    path: AuthRoutes.verifyemail,
    element: <VerifyEmail />,
  },

  {
    path: AuthRoutes.signup,
    element: <Register />,
  },
  {
    path: AuthRoutes.login,
    element: <Login />,
  },

  {
    path: BlogRoutes.blog,
    element: <Blog />,
  },

  {
    path: BlogRoutes.blogPost,
    element: <BlogPost />,
  },

  {
    path: PublicRoutes.freelancers,
    element: <Profilfreelances />,
  },

  {
    path: PublicRoutes.freelancersByCountry,
    element: <Profilfreelances />,
  },

  {
    path: PublicRoutes.freelancersByJob,
    element: <Profilfreelances />,
  },

  {
    path: PublicRoutes.freelancersBySkill,
    element: <Profilfreelances />,
  },

  {
    path: PublicRoutes.freelancersByLanguage,
    element: <Profilfreelances />,
  },

  {
    path: PublicRoutes.freelancersByRate,
    element: <Profilfreelances />,
  },

  {
    path: PublicRoutes.projects,
    element: <Jobs />,
  },

  {
    path: PublicRoutes.projectsByCategory,
    element: <Jobs />,
  },

  {
    path: PublicRoutes.projectsByApplications,
    element: <Jobs />,
  },

  {
    path: PublicRoutes.projectsBySkill,
    element: <Jobs />,
  },

  {
    path: PublicRoutes.projectsByLocation,
    element: <Jobs />,
  },

  {
    path: PublicRoutes.projectsByLanguage,
    element: <Jobs />,
  },

  {
    path: PublicRoutes.products,
    element: <MarketplaceComponents />,
  },

  {
    path: PublicRoutes.productsBySkill,
    element: <MarketplaceComponents />,
  },

  {
    path: PublicRoutes.productsByLanguage,
    element: <MarketplaceComponents />,
  },

  {
    path: PublicRoutes.trainings,
    element: <VideosTrainingsComponnents />,
  },

  {
    path: PublicRoutes.trainingsBySkill,
    element: <VideosTrainingsComponnents />,
  },

  {
    path: PublicRoutes.trainingsByLanguage,
    element: <VideosTrainingsComponnents />,
  },

  // Freelancer Routes Config
  {
    path: DashboardFreelancerRoutes.projects,
    element: (
      <>
        <ControllerGuard />
        <Projects />
      </>
    ),
  },

  // Company Routes Config
  {
    path: DashboardCompanyRoutes.chat,
    element: (
      <>
        <ControllerCompany />
        <ChatEntreprise />
      </>
    ),
  },

  {
    path: DashboardCompanyRoutes.profil,
    element: (
      <>
        <ControllerGuard />
        <Profil />
      </>
    ),
  },
  {
    path: DashboardRecruterRoutes.itGalaxyContactor,
    element: (
      <>
        <ControllerGuard />
        <ItGalaxyContactor />
      </>
    ),
  },

  {
    path: DashboardRecruterRoutes.itGalaxyRecruitment,
    element: (
      <>
        <ControllerGuard />
        <Recruitment />
      </>
    ),
  },

  {
    path: DashboardRecruterRoutes.recruitmentDash,
    element: (
      <>
        <ControllerGuard />
        <RecruitmentDash />
      </>
    ),
  },
  {
    path: DashboardRecruterRoutes.shareCandidat,
    element: (
      <>
        <ControllerGuard />
        <ShareCandidat />
      </>
    ),
  },
  {
    path: DashboardRecruterRoutes.shareJob,
    element: (
      <>
        <ControllerGuard />
        <ShareJob />
      </>
    ),
  },

  {
    path: DashboardCompanyRoutes.company,
    element: (
      <>
        <ControllerGuard />
        <Company />
      </>
    ),
  },

  {
    path: DashboardFreelancerRoutes.chat,
    element: (
      <>
        <ControllerCandidat />
        <ChatCandidat />
      </>
    ),
  },
  {
    path: DashboardFreelancerRoutes.trainer,
    element: (
      <>
        <ControllerGuard />
        <DashboardITProducer />
      </>
    ),
  },

  {
    path: TrainingRoutes.videosTrainings,
    element: (
      <>
        <ControllerGuard />
        <DashboardTraining />
      </>
    ),
  },

  {
    path: ItGalaxyMarketplaceRoutes.marketplace,
    element: (
      <>
        <ControllerGuard />
        <Marketplace />
      </>
    ),
  },

  {
    path: DashboardCompanyRoutes.joinus,
    element: <JoinUsWithTranslations />,
  },

  {
    path: AuthRoutes.ChangePassword,
    element: (
      <ModalForgotPassword
        show={true}
        onBack={"handleBacktoLogin"}
        onHide={"closeModal"}
      />
    ),
  },

  {
    path: DashboardFreelancerRoutes.wallet,
    element: (
      <>
        <ControllerGuard />
        <Wallet />
      </>
    ),
  },

  {
    path: DashboardRecruterRoutes.chat,
    element: (
      <>
        <ControllerRecruter />
        <ChatRecruter />
      </>
    ),
  },

  {
    path: DashboardAccountingRoutes.chat,
    element: (
      <>
        <ControllerAccounting />
        <ChatAccounting />
      </>
    ),
  },
  {
    path: DashboardAccountingRoutes.signupAccounting,
    element: (
      <RegisterAccounting
        openModalRegister={true}
        setOpenModalRegister={true}
        switchBetweenModals={false}
        proxy={"dashboard"}
        back={false}
      />
    ),
  },
  {
    path: DashboardAccountingRoutes.homepageAccounting,
    element: <DashboardHomePageAccounting />,
  },
  {
    path: DashboardAccountingRoutes.myWorkingFolder,
    element: (
      <>
        <ControllerGuard />
        <MyWorkingFolders />
      </>
    ),
  },

  {
    path: ItGalaxyMarketplaceRoutes.server,
    element: (
      <>
        <ControllerGuard />
        <ItGalaxyProductCreateServer />{" "}
      </>
    ),
  },
  {
    path: ItGalaxyMarketplaceRoutes.database,
    element: (
      <>
        <ControllerGuard /> <ItGalaxyProductDatabase />
      </>
    ),
  },
  {
    path: ItGalaxyMarketplaceRoutes.itgalaxyProductPurchased,
    element: (
      <>
        <ControllerGuard />
        <ItGalaxyProductPurchased />
      </>
    ),
  },

  {
    path: ItGalaxyMarketplaceRoutes.productPurchased,
    element: (
      <>
        <ControllerGuard /> <ProductPurchased />
      </>
    ),
  },

  {
    path: ItGalaxyMarketplaceRoutes.itgalaxyProductDetails,
    element: (
      <>
        <ControllerGuard /> <ItGalaxyProductDetail />
      </>
    ),
  },
  {
    path: TrainingRoutes.videosTrainings,
    element: (
      <>
        <ControllerGuard /> <DashboardTraining />
      </>
    ),
  },

  {
    path: DashboardFreelancerRoutes.myproduct,
    element: (
      <>
        <ControllerGuard /> <MyProductEdit />
      </>
    ),
  },

  {
    path: "*",
    element: <NotFound />,
  },
];
