import {
  AdvancedSearchButtonModalContainer,
  SearchBar,
  SearchInputModalContainer,
} from "components/Jobs/styled";
import { FormProvider, useForm } from "react-hook-form";
import GenericInput from "../../Inputs/GenericInput/GenericInput";
import ModalComponent from "../ModalComponent";
import { formConfig } from "./AdvancedSearch.constants";
import {
  SearchBarModalStyle,
  SearchColumnstyle,
  SearchTitleStyle,
  customStyles,
  customStylesSearchModal,
} from "./ModalAdvancedSearch.style";

const ModalAdvancedSearch = (props) => {
  const formMethods = useForm({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleChangeFilterType = (selectedOptions) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      filterType: selectedOptions || [
        { value: "Contrats", label: "Contrats" },
        { value: "Projects", label: "Projects" },
      ],
    }));
  };

  const handleChangePostion = (selectedOptions) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      positions: selectedOptions || [],
    }));
  };

  const handleChangeSkills = (selectedOptions) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      skills: selectedOptions || [],
    }));
  };

  const handleChangeApplications = (e) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      applications: e.value,
    }));
  };

  const handleChangeLocations = (selectedOptions) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      location: selectedOptions || [],
    }));
  };

  const handleChangeLanguages = (selectedOptions) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      languages: selectedOptions || [],
    }));
  };

  const handleChangeCreatedat = (e) => {
    props.setFormData((prevFormData) => ({
      ...prevFormData,
      createdat: e.value,
    }));
  };

  const RenderBodyModalAdvancedSearch = (
    <FormProvider {...formMethods}>
      <SearchBarModalStyle>
        {!props.hideCategory && (
          <SearchColumnstyle>
            <SearchTitleStyle>Job Category</SearchTitleStyle>
            <SearchInputModalContainer>
              <GenericInput
                inputObject={{
                  ...formConfig.position,
                  label: "position",
                }}
                styles={customStylesSearchModal}
                onChange={(e) => handleChangePostion(e)}
                disabledForm={false}
              />
            </SearchInputModalContainer>
          </SearchColumnstyle>
        )}
        {!props.hideApplications && (
          <SearchColumnstyle>
            <SearchTitleStyle>Number of applications</SearchTitleStyle>
            <SearchInputModalContainer>
              <GenericInput
                inputObject={{
                  ...formConfig.nbr_applications,
                  label: "nbr_applications",
                }}
                styles={customStylesSearchModal}
                onChange={(e) => handleChangeApplications(e)}
                disabledForm={false}
              />
            </SearchInputModalContainer>
          </SearchColumnstyle>
        )}
      </SearchBarModalStyle>
      <SearchBarModalStyle>
        <SearchColumnstyle>
          {!props.hideSkills && (
            <>
              <SearchTitleStyle>Skills</SearchTitleStyle>
              <SearchInputModalContainer>
                <GenericInput
                  inputObject={{
                    ...formConfig.skills,
                    label: "skills",
                  }}
                  styles={customStylesSearchModal}
                  onChange={(e) => handleChangeSkills(e)}
                  disabledForm={false}
                />
              </SearchInputModalContainer>
            </>
          )}
        </SearchColumnstyle>
        <SearchColumnstyle>
          {!props.hideLocations && (
            <>
              <SearchTitleStyle>Locations</SearchTitleStyle>
              <SearchInputModalContainer>
                <GenericInput
                  inputObject={{
                    ...formConfig.locations,
                    label: "locations",
                  }}
                  styles={customStylesSearchModal}
                  onChange={(e) => handleChangeLocations(e)}
                  disabledForm={false}
                />
              </SearchInputModalContainer>
            </>
          )}
        </SearchColumnstyle>
      </SearchBarModalStyle>
      <SearchBarModalStyle>
        <SearchColumnstyle>
          {!props.hideLanguages && (
            <>
              <SearchTitleStyle>Languages</SearchTitleStyle>
              <SearchInputModalContainer>
                <GenericInput
                  inputObject={{
                    ...formConfig.languages,
                    label: "languages",
                  }}
                  styles={customStylesSearchModal}
                  onChange={(e) => handleChangeLanguages(e)}
                  disabledForm={false}
                />
              </SearchInputModalContainer>
            </>
          )}
        </SearchColumnstyle>
        <SearchColumnstyle>
          <SearchTitleStyle>CreatedAt</SearchTitleStyle>
          <SearchInputModalContainer>
            <GenericInput
              inputObject={{
                ...formConfig.CreatedAt,
                label: "CreatedAt",
              }}
              styles={customStylesSearchModal}
              onChange={(e) => handleChangeCreatedat(e)}
              disabledForm={false}
            />
          </SearchInputModalContainer>
        </SearchColumnstyle>
      </SearchBarModalStyle>
      <SearchColumnstyle>
        <SearchTitleStyle>
          Tap some keyswords thats describe your search (use ';' to separat many
          keywords ) :
        </SearchTitleStyle>
        <GenericInput
          inputObject={{
            ...formConfig.keywords,
            label: "keywords",
          }}
          onChange={(e) => handleChange(e)}
          disabledForm={false}
        />
      </SearchColumnstyle>
    </FormProvider>
  );

  const RenderFooter = (
    <>
      <AdvancedSearchButtonModalContainer onClick={props.handleSearchSubmit}>
        Submit{" "}
      </AdvancedSearchButtonModalContainer>
    </>
  );
  const RenderHeader = (
    <SearchBar>
      <FormProvider {...formMethods}>
        <GenericInput
          inputObject={{
            ...formConfig.filterType,
            label: "filterType",
          }}
          styles={customStyles}
          onChange={(e) => handleChangeFilterType(e)}
          disabledForm={false}
        />
      </FormProvider>
    </SearchBar>
  );

  return (
    <ModalComponent
      show={props.confirmShow}
      closeModal={props.closeModal}
      body={RenderBodyModalAdvancedSearch}
      header={RenderHeader}
      footer={RenderFooter}
      bodyPadding={"15px 10px 0px 10px"}
      minWidth={"56vw"}
      Height={"70vh"}
    />
  );
};

export default ModalAdvancedSearch;
