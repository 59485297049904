import fr from './fr';
import en from './en';

// Debug the loaded translations
console.log('Current translations:', { fr, en });

export const messages = {
  fr,
  en,
};

export const defaultLocale = 'fr';
