import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { Helmet } from "react-helmet"; // Import Helmet for SEO
import Register from "../../Authentification/modals/register";
import FooterContent from "./FooterContent/FooterContent";
import {
  FlexContainer,
  RootStyle,
  StackStyle,
  SubscribeButtonStyle,
  TalkButtonStyle,
  TitleStyle,
  TypographyStyle,
} from "./FooterHome.style";

function FooterHome() {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState("");
  const [openModalRegister, setOpenModalRegister] = useState(false);

  const formMethods = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });


  const handleModalRegister = () => {
    setOpenModalRegister(true);
  };

  const handleModalCloseRegister = () => {
    setOpenModalRegister(false);
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <RootStyle>
      <Helmet>
        <title>{intl.formatMessage({ id: "footer.seo.title", defaultMessage: "Home Page - ItGalaxy" })}</title>
        <meta name="description" content={intl.formatMessage({ id: "footer.seo.description", defaultMessage: "Abonnez-vous à notre newsletter et restez informé des dernières nouvelles." })} />
      </Helmet>

      <StackStyle
        direction={isMobile ? "column" : "row"}
        style={{ justifyContent: "space-around" }}
      >
        <StackStyle
          width={isMobile ? "100%" : "40%"}
          style={{ padding: isMobile ? "10px" : "0px" }}
        >
          <TitleStyle>
            {intl.formatMessage({ id: "footer.title" })}
          </TitleStyle>
          <TypographyStyle>
            {intl.formatMessage({ id: "footer.description" })}
          </TypographyStyle>
        </StackStyle>
        <StackStyle
          spacing={2.5}
          width={isMobile ? "100%" : "40%"}
          style={{ padding: isMobile ? "10px" : "0px" }}
        >
          <FormProvider {...formMethods}>
             <FlexContainer spacing={2.5} direction={"row"}>
              <TalkButtonStyle 
                onClick={handleModalRegister} 
                aria-label={intl.formatMessage({ id: "footer.buttons.talk" })} // Accessibility
              >
                {intl.formatMessage({ id: "footer.buttons.talk" })}
              </TalkButtonStyle>
              <SubscribeButtonStyle 
                onClick={handleModalRegister} 
                aria-label={intl.formatMessage({ id: "footer.buttons.subscribe" })} // Accessibility
              >
                {intl.formatMessage({ id: "footer.buttons.subscribe" })}
              </SubscribeButtonStyle>
            </FlexContainer>
          </FormProvider>
        </StackStyle>
      </StackStyle>
      <FooterContent />
      <Register
        openModalRegister={openModalRegister}
        setOpenModalRegister={setOpenModalRegister}
        handleModalRegister={handleModalCloseRegister}
        proxy={"dashboard"}
      />
    </RootStyle>
  );
}

export default FooterHome;