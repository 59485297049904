import { paletteITgalaxy } from "assets/TypoITgalaxy/PaletteCouleur";
import styled from "styled-components";

export const PathToRouteContainer = styled.div`
  margin-left: 50px;
`;

export const EditProfileContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  background-color: #407bff21;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 30px 0;
`;

export const EditProfileCont = styled.div`
  width: 95%;
`;

export const EditProfilePage = styled.div`
  margin-top: 5%;
  display: flex;
  width: 100%;
  heigh: 100%;
`;

export const ContentEveryCard = styled.div`
  width: 98%;
  height: 90%;
`;

export const FreelancerInformationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 12.5px 0;
  width: 95%;
  align-self: center;
  margin-top: 30px;
`;

export const DesignationCardContent = styled.div`
  font-size: 14pw;
  font-style: normal;
  color: ${paletteITgalaxy.colorTextITgalaxy};
`;
export const ExplicationCardContent = styled.p`
  font-size: 10px;
  color: #717171;
  font-style: normal;
`;
export const InformationGeneralContainer = styled.div`
  width: 54.5%;
  height: 334px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InformationContainer = styled.div`
  width: 100%;
  height: 228px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const ImageFreelancerContainer = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
`;

export const PenEditPhoto = styled.button`
  position: absolute;
  top: 10px;
  left: 10px;
  border: none;
  background-color: rgba(255, 255, 255, 0.54);
  border-radius: 3px;
  width: 34px;
  height: 34px;
`;

export const InputFreelanceContainer = styled.div`
  width: 77%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin: 5px 0;
`;

export const LabelContent = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  text-decoration: none;
  color: #8e8e8e;
`;

export const InputContainer = styled.div`
  width: 80%;
`;

export const InputFreelancer = styled.input`
  border: 1px solid #e5e5ee;
  border-radius: 5px;
  background-color: #ffffff;
`;

export const LanguageContainer = styled.div`
  border: 1px solid #e5e5ee;
  border-radius: 5px;
  background-color: #ffffff;
  heigh: 30px;
  width: 99%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 4px;
`;

export const ItemLanguageContainer = styled.button`
  width: auto;
  height: auto;
  border: 1px solid #203442;
  border-radius: 20px;
  padding: 4px;
  font-size: 10px;
  color: ${paletteITgalaxy.colorTextITgalaxy};
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: auto;
`;

export const ContainerAboutSkills = styled.div`
  width: 44.5%;
  height: 334px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const AboutFreelancerContainer = styled.div`
  width: 100%;
  height: 178px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SkillsFreelancerContainer = styled.div`
  width: 100%;
  height: 130px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MontantGagneAndCVContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 196px;
  margin: 12.5px 0;
  width: 95%;
  align-self: center;
`;

export const MontantGagneContent = styled.div`
  width: 54.5%;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CurriculumVitaeContent = styled.div`
  width: 44.5%;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const pageEdit = styled.div`
  display: flex;
`;

export const PaiementAndCVCommentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 196px;
  margin: 12.5px 0;
  width: 95%;
  align-self: center;
`;

export const PaiementContent = styled.div`
  width: 54.5%;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CommentContent = styled.div`
  width: 44.5%;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PortfolioAndStatusContainer = styled.div`
  // height: 229px;
  // border-radius: 10px;
  // background-color: #ffffff;
  // box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  // margin: 12.5px 0;
  // width: 95%;
  // align-self: center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 196px;
  margin: 12.5px 0;
  width: 95%;
  align-self: center;
`;

export const PortfolioContent = styled.div`
  width: 54.5%;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatusFreelancerContent = styled.div`
  width: 44.5%;
  height: 100%;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StatusFreelancer = styled.div``;

export const ExperienceContainer = styled.div`
  height: 154px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  margin: 12.5px 0;
  width: 95%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const EducationContainer = styled.div`
  height: 154px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  margin: 12.5px 0;
  width: 95%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const QualificationContainer = styled.div`
  height: 154px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 22.8px 0px rgba(0, 0, 0, 0.08);
  margin: 12.5px 0;
  width: 95%;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
