import AddNewJob from "components/AddNewJob/AddNewJob";
import ModalShowMyOffre from "components/ModalITgalaxy/ModalShowMyOffre/ModalShowMyOffre";
import Pagination from "components/Paginations/Pagination";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import { useGetAppelOffresQuery } from "../../redux/api/appeloffres/appeloffreApi";
import GenericInput from "../Inputs/GenericInput/GenericInput";
import { formConfig } from "./MyOffers.constants";

import {
  AddNewJobButtonstyle,
  AllCard,
  BackButton,
  CardContainers,
  CardProject,
  CardProjectTitle,
  ContainerWrapper,
  DatePost,
  JobLabelsContainer,
  ListingJobs,
  NotFoundTextStyle,
  SearchBar,
  SearchFilter,
  SearchIconInput,
  SearchInputContainer,
  StyleAllProjects,
  ViewLabelJobs,
  ViewLabelMyJobs,
  Wrapper,
} from "./styled";
const Vector = `${process.env.REACT_APP_CDN_ITGALAXY}/assets/IconITgalaxy/Vector.svg`;

export default function MyOffers({ id, setAlljobs }) {
  const formMethods = useForm({});
  const [selectedItem, setSelectedItem] = useState("");
  const [showModalAppeloffre, setShowModalAppeloffre] = useState(false);
  const [filterType, setFilterType] = useState("ACTIVE");
  const [title, setTitle] = useState("");
  const [addNewJob, setAddNewJob] = useState(false); // Fixed naming
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(6000000);
  // Initialize appeloffresData as an empty array
  const [appeloffresData, setAppeloffresData] = useState([]);

  const handleChangeFilter = (e) => {
    setFilterType(e.target.value);
  };

  const handleChangeSearch = useCallback((e) => {
    if (e.key === "Enter") {
      setTitle(e.target.value);
    }
  }, []);

  const params = `?page=${currentPage}&limit=${recordsPerPage}&recruterId=${id}${
    title ? `&title=${title}` : ""
  }`;

  // Fetch user projects with the updated filter and title
  const {
    data: appeloffreData,
    isLoading: appeloffresDataLoading,
    error: appeloffresDataError,
    refetch,
  } = useGetAppelOffresQuery(params);

  // Update appeloffresData when appeloffreData is available
  useEffect(() => {
    if (appeloffreData) {
      setAppeloffresData(appeloffreData.data);
    }
  }, [appeloffreData]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setShowModalAppeloffre(true);
  };

  const handleAddNewJob = () => {
    setAddNewJob(true);
  };

  const closeAddNewJob = () => {
    setAddNewJob(false);
  };

  return (
    <>
      {!addNewJob ? (
        <Wrapper>
          <ContainerWrapper>
            <ListingJobs>
              <SearchBar>
                <FormProvider {...formMethods}>
                  <SearchInputContainer onKeyDown={handleChangeSearch}>
                    <GenericInput
                      inputObject={{ ...formConfig.title, label: "title" }}
                      disabledForm={false}
                    />
                  </SearchInputContainer>
                </FormProvider>
                <AddNewJobButtonstyle onClick={handleAddNewJob}>
                  Add New Job
                </AddNewJobButtonstyle>
              </SearchBar>
              <hr />
              {appeloffresDataLoading ? (
                <CardContainers>
                  <Spinner />
                </CardContainers>
              ) : appeloffresDataError ? (
                <CardContainers>
                  <NotFoundTextStyle>
                    🚀 Error loading projects, please try again later! 🚀
                  </NotFoundTextStyle>
                </CardContainers>
              ) : (
                <>
                  <JobLabelsContainer>
                    <ViewLabelMyJobs onClick={() => setAlljobs(true)}>
                      View all jobs
                    </ViewLabelMyJobs>
                    <ViewLabelJobs>My jobs</ViewLabelJobs>
                  </JobLabelsContainer>
                  {appeloffresData.length === 0 ? (
                    <CardContainers>
                      <NotFoundTextStyle>
                        No job was created ...
                      </NotFoundTextStyle>
                    </CardContainers>
                  ) : (
                    <>
                      <AllCard>
                        {appeloffresData.map((appeloffredata) => (
                          <CardProject
                            key={appeloffredata.id}
                            onClick={() => handleItemClick(appeloffredata)}
                          >
                            <StyleAllProjects>
                              <CardProjectTitle>
                                {appeloffredata && appeloffredata.title
                                  ? appeloffredata.title.length > 10
                                    ? appeloffredata.title.substring(0, 20) +
                                      "..."
                                    : appeloffredata.title
                                  : "No Title Available"}
                              </CardProjectTitle>
                              <DatePost>
                                <span
                                  style={{
                                    color:
                                      appeloffredata.status === "CREATED"
                                        ? "orange"
                                        : appeloffredata.status === "VALIDATED"
                                        ? "var(--Success-Success200, rgba(21, 176, 151, 1))"
                                        : appeloffredata.status === "FINISHED"
                                        ? "green"
                                        : appeloffredata.status === "DECLINED"
                                        ? "red"
                                        : appeloffredata.status === "SUSPEND"
                                        ? "red"
                                        : "green", // fallback color
                                  }}
                                >
                                  Status: {appeloffredata.status}
                                </span>
                                <br />
                                <span>
                                  Created:{" "}
                                  {appeloffredata.createdAt
                                    ? new Date(
                                        appeloffredata.createdAt
                                      ).toDateString()
                                    : "Date not available"}
                                </span>
                              </DatePost>
                            </StyleAllProjects>
                          </CardProject>
                        ))}
                      </AllCard>
                      <div className="col-lg-12 col-md-12 col-12 mt-4">
                        {appeloffreData?.pagination?.totalPages > 1 && (
                          <Pagination
                            nPages={appeloffreData.pagination.totalPages}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                          />
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </ListingJobs>
          </ContainerWrapper>
        </Wrapper>
      ) : (
        <Wrapper>
          <ContainerWrapper>
            <ListingJobs>
              <BackButton onClick={closeAddNewJob}>
                <img src={Vector} style={{ width: "0.83vw" }} alt="vector" />
              </BackButton>
              <AddNewJob
                id={id}
                refetch={refetch}
                setAddNewJob={setAddNewJob}
              />
            </ListingJobs>
          </ContainerWrapper>
        </Wrapper>
      )}
      {selectedItem && (
        <ModalShowMyOffre
          offre={selectedItem}
          confirmShow={showModalAppeloffre}
          closeModal={setShowModalAppeloffre}
          refetch={refetch}
        />
      )}
    </>
  );
}
