import styled from "styled-components";

export const InlineBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CenteredInlineFlexMB20PxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

export const QuestionContainer = styled.div`
  margin-bottom: 1rem;
  width: 100%;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
  margin-top: 1rem;
`;

export const UserLabel = styled.div`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

export const FormWrapper = styled.div`
  width: 100%;
`;

export const Answer = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const ML20PxBoldContainer = styled.div`
  margin-left: 20px;
  font-weight: bold;
`;

export const Vh90WidthContainer = styled.div`
  width: 100%;
  margin-left: 0;
`;

export const InlineFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
