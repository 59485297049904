import { validationSchemas } from "../validationSchemas";

export const validateStep = async (formMethods, currentStep) => {
  const currentSchema = validationSchemas[currentStep];

  if (!currentSchema) {
    return false;
  }

  const isValid = await formMethods.trigger();

  return isValid;
};

export const validateChapterFields = async (formMethods, chapterIndex) => {
  const isValid = await formMethods.trigger([
    `chapters.${chapterIndex}.chapterTitle`,
  ]);
  return isValid;
};

export const validateTrainingType = async (formMethods) => {
  const isValid = await formMethods.trigger("product_type");
  return isValid;
};
