export const VIDEO_TRAINING = "VIDEOSTRAINING";
export const APPLICATION = "APPLICATION";
export const VIDEO_TRAINING_ITEMS = [
  { id: 1, title: "Product Type" },
  { id: 2, title: "Video training Description" },
  {
    id: 3,
    title: "Videos training content",
    subModules: [], // Initially empty to allow dynamic additions
  },
  { id: 4, title: "Videos training price" },
  { id: 5, title: "Publish your Videos training" },
];

export const VIDEO_TRAINING_ITEMS_UPDATE = [
  { id: 1, title: "Product Type" },
  { id: 2, title: "Video training Description" },
  {
    id: 3,
    title: "Videos training content",
    subModules: [], // Initially empty to allow dynamic additions
  },
  { id: 4, title: "Videos training price" },
];

export const APPLICATION_ITEMS = [
  { id: 1, title: "Product Type" },
  { id: 2, title: "IT Product Description" },
  {
    id: 3,
    title: "IT Product content",
    subModules: [], // Initially empty to allow dynamic additions
  },
  { id: 4, title: "IT Product price" },
  { id: 5, title: "Publish your IT Product" },
];

export const APPLICATION_ITEMS_UPDATE = [
  { id: 1, title: "Product Type" },
  { id: 2, title: "IT Product Description" },
  {
    id: 3,
    title: "IT Product content",
    subModules: [], // Initially empty to allow dynamic additions
  },
  { id: 4, title: "IT Product price" },
];
