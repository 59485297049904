import ImageProfilCard from "components/ImageProfilCard/ImageProfilCard";
import RenderStars from "components/RenderStars/RenderStars";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { categoryData } from "data/categoryData";
import { getURL } from "../../../../redux/api/uploads/uploadSlice";
import { StyledLoaderContainer } from "../../styeldComponants";
import EditCVProfileCandidat from "./EditCVProfileCandidat";
import EditImageProfilCandidat from "./EditImageProfileCandidat";
import EditFormCandidatProfil from "./EditProfileCandidat";

import {
  CommentUser,
  CommentedStyle,
  CompetenceArea,
  CompetenceAreaSkills,
  CompetenceLabel,
  CompetenceLabelLanguages,
  FullScreenIcon,
  StarContainer,
  StyleWithIcon,
  StyledAboutMeCard,
  StyledAboutMeText,
  StyledAvailableText,
  StyledBlackBackground,
  StyledComments,
  StyledCommentsContainer,
  StyledDot,
  StyledEditImageIcon,
  StyledEditProfileButton,
  StyledLeftSideBar,
  StyledLeftSideBarAvailable,
  StyledLeftSideBarContainer,
  StyledLeftSideBarImage,
  StyledLeftSideBarLightParagraph,
  StyledLeftSideBarLocationContainer,
  StyledLeftSideBarName,
  StyledLeftSideBarNameStyle,
  StyledLeftSideBarProfileAvgDay,
  StyledLeftSideBarProfileDesc,
  StyledLeftSideBarUSerOtherInfo,
  StyledLink,
  StyledPortfolioSectionContainer,
  StyledReadMoreButton,
  StyledRightSideBarContainer,
  StyledSideBarHeader,
  StyledSidebarContent,
  StyledViewCandidatProfil,
  StyledWebViewCardCV,
  StyledWebViewCardExp,
  TextComment,
  TitleProfile,
} from "./styled";
import { Pencil,MapPin } from 'lucide-react'; // Import the Pencil icon from Lucide
const LinkedInIcon = `${process.env.REACT_APP_CDN_ITGALAXY}/assets/IconITgalaxy/linkedin-icon-black.png`;
const GithubIcon  = `${process.env.REACT_APP_CDN_ITGALAXY}/assets/IconITgalaxy/github-icon.png`;

const ViewCandidatProfil = ({ data, setDataUser, editProfil }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isFullScreenExp, setIsFullScreenExp] = useState(false);
  const [isFullScreenCV, setIsFullScreenCV] = useState(false);
  const [editModalProfileCVOpen, setEditModalProfileCVOpen] = useState(false);
  const [editModalProfilePictureOpen, setEditModalProfilePictureOpen] =
    useState(false);
  const [previewCV, setPreviewCV] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  const dispatch = useDispatch();

  const getLabelByValue = (value) => {
    const category = categoryData.find(item => item.value === value);
    return category ? category.title : null; // returns null if value not found
  };

  const getUrlData = async () => {
    setLoading(true);
    try {
      const url = await dispatch(
        getURL({ location: `candidats/${data.id}/profil/cv` })
      );

      if (url.Contents && url.Contents.length > 0) {
        const cvKey = url.Contents.map((item) => item.Key)[0];
        setPreviewCV(`${process.env.REACT_APP_S3_URL}/${cvKey}`);
      } else {
        console.error("No CV contents found");
        setPreviewCV(null);
      }
    } catch (error) {
      console.error("Error fetching the CV URL:", error);
      setPreviewCV(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUrlData();
  }, [data.id]); // Added dependency to only run when data.id changes

  if (!data) {
    return (
      <StyledLoaderContainer>
        <Spinner style={{ width: "100px", height: "100px" }} />
      </StyledLoaderContainer>
    );
  }

  const toggleFullScreenExp = () => setIsFullScreenExp((prev) => !prev);
  const toggleFullScreenCV = () => setIsFullScreenCV((prev) => !prev);
  const toggleReadMore = () => setIsExpanded((prev) => !prev);

  const openEditModal = () => setEditModalOpen(true);
  const closeEditModal = () => setEditModalOpen(false);

  const openEditModalImage = () => setEditModalProfilePictureOpen(true);
  const closeEditModalImage = () => setEditModalProfilePictureOpen(false);

  const openEditModalCV = () => setEditModalProfileCVOpen(true);
  const closeEditModalCV = () => setEditModalProfileCVOpen(false);

  const handleSaveChanges = (updatedData) => {
    const newData = { ...data, ...updatedData };
    setDataUser(newData);
    closeEditModal();
  };

  const handleSaveChangesProfileImage = (updatedData) => {
    const newData = { ...data, ...updatedData };
    setDataUser(newData);
    closeEditModalImage();
  };

  const handleSaveChangesProfileCV = (updatedData) => {
    const newData = { ...data, ...updatedData };
    setDataUser(newData);
    closeEditModalCV();
  };

  const showReadMore = data?.profile_description?.length > 300;

  return (
    <StyledViewCandidatProfil editProfil={editProfil}>
      <StyledLeftSideBar isFullScreenCV={isFullScreenCV} isFullScreenExp={isFullScreenExp}>
        <StyledSideBarHeader>
          <StyledBlackBackground />
          <StyledSidebarContent>
            <StyledLeftSideBarImage style={{ position: "relative" }}>
              <ImageProfilCard
                id={data.id}
                type={"candidats"}
                typeimg={"profil"}
              />
              {editProfil && (
                <StyledEditImageIcon onClick={openEditModalImage}>
                  <Pencil size={16} color="black" />
                </StyledEditImageIcon>
              )}
            </StyledLeftSideBarImage>
          </StyledSidebarContent>
        </StyledSideBarHeader>

        <StyledLeftSideBarAvailable>
          <StyledDot isActive={data.status === "ACTIVE"} />
          <StyledAvailableText>
            {data.status === "ACTIVE" ? "AVAILABLE" : "UNAVAILABLE"}
          </StyledAvailableText>
        </StyledLeftSideBarAvailable>

        <StyledLeftSideBarName>
          <StyledLeftSideBarNameStyle>{data.name}</StyledLeftSideBarNameStyle>
        </StyledLeftSideBarName>

        <StyledLeftSideBarUSerOtherInfo>
          <StyledLeftSideBarProfileDesc>
            {getLabelByValue(data.job)}
          </StyledLeftSideBarProfileDesc>
          {editProfil && (
            <StyledLeftSideBarLightParagraph>
              {data.email}
            </StyledLeftSideBarLightParagraph>
          )}
          <StyledLeftSideBarProfileAvgDay>
            {data.hour_rate} euro / hour
          </StyledLeftSideBarProfileAvgDay>
          <StyledLeftSideBarLocationContainer>
          <MapPin size={30} style={{ paddingRight: "10px" }} /> {/* Using Lucide MapPin */}
            <StyledLeftSideBarLightParagraph>
              {data.country_details}
            </StyledLeftSideBarLightParagraph>
          </StyledLeftSideBarLocationContainer>
        </StyledLeftSideBarUSerOtherInfo>

        <StyledLeftSideBarContainer>
          <StyledLink
            href={
              data?.linkedin?.startsWith('http://') || data?.linkedin?.startsWith('https://')
                ? data?.linkedin
                : `https://${data?.linkedin}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
             
            <img style={{ height: "4%", width: "4%", marginRight : "2%"}} src={LinkedInIcon} alt="LinkedIn" />
             {"Linkedin Profil"}
            </span>
            <i className="fas fa-external-link-alt"></i>
          </StyledLink>

          <StyledLink
              href={
                data?.github?.startsWith('http://') || data?.github?.startsWith('https://')
                  ? data?.github
                  : `https://${data?.github}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>
              <img style={{ height: "4%", width: "4%" , marginRight : "2%" }} src={GithubIcon} alt="Github" />
              {"Github Profil"}
              </span>
              <i className="fas fa-external-link-alt"></i>

            </StyledLink>

          <hr style={{ borderTop: "1px solid", opacity: "1", width: "100%" }} />

          <CompetenceAreaSkills>
            {data?.skills?.map((c, index) => (
              <CompetenceLabel key={index}>{c}</CompetenceLabel>
            ))}
          </CompetenceAreaSkills>
          <hr style={{ borderTop: "1px solid", opacity: "1", width: "100%" }} />
          <CompetenceAreaSkills>
            {data?.languages?.map((c, index) => (
              <CompetenceLabelLanguages key={index}>
                {c}
              </CompetenceLabelLanguages>
            ))}
          </CompetenceAreaSkills>

          {editProfil && (
            <StyledEditProfileButton onClick={openEditModal}>
            Edit your profile  <Pencil size={16} color="white" /> 
            </StyledEditProfileButton>
          )}
        </StyledLeftSideBarContainer>
      </StyledLeftSideBar>

      <StyledRightSideBarContainer>
        <StyledAboutMeCard
          isFullScreenCV={isFullScreenCV}
          isFullScreenExp={isFullScreenExp}
        >
          <TitleProfile>About Me</TitleProfile>
          <StyledAboutMeText isExpanded={isExpanded}>
            {isExpanded
              ? data.profile_description
              : data.profile_description
                ? `${data.profile_description.slice(0, 200)}${data.profile_description.length > 200 ? "..." : ""
                }`
                : "No description available."}
          </StyledAboutMeText>

          {showReadMore && (
            <StyledReadMoreButton onClick={toggleReadMore}>
              {isExpanded ? "Read Less" : "Read More"}
              <i className={`fas fa-chevron-${isExpanded ? "up" : "down"}`} />
            </StyledReadMoreButton>
          )}
        </StyledAboutMeCard>

        <StyledPortfolioSectionContainer>
          <StyledWebViewCardCV
            isFullScreenExp={isFullScreenExp}
            editProfil={editProfil}
            className={isFullScreenCV ? "full-screen" : ""}
          >
            <StyleWithIcon>
              <TitleProfile>Curriculum Vitae</TitleProfile>
              <FullScreenIcon
                className={`fas ${isFullScreenCV ? "fa-compress" : "fa-expand"
                  }`}
                onClick={toggleFullScreenCV}
              />
              {editProfil && (
                 <Pencil size={24} color="#000" onClick={openEditModalCV} /> 
              )}
            </StyleWithIcon>

            <iframe
              src={previewCV} // Fallback in case previewCV is null
              width="100%"
              height="100%" // Adjust height as needed
              title="CV Preview"
              style={{ border: "none", padding: 0, margin: 0 }}
            />
          </StyledWebViewCardCV>

          <StyledWebViewCardExp
            isFullScreenCV={isFullScreenCV}
            editProfil={editProfil}
            className={isFullScreenExp ? "full-screen" : ""}
          >
            <StyleWithIcon>
              <TitleProfile>ItGalaxy Experiences</TitleProfile>
              <FullScreenIcon
                className={`fas ${isFullScreenExp ? "fa-compress" : "fa-expand"
                  }`}
                onClick={toggleFullScreenExp}
              />
            </StyleWithIcon>

            <CompetenceArea>
              <StyledCommentsContainer>
                {data?.comments?.map((c) => (
                  <StyledComments key={c.id}>
                    <CommentedStyle>
                      {/* Add profile image for the commented user if needed */}
                      <CommentUser>{c.commentedUserName}</CommentUser>
                    </CommentedStyle>
                    <TextComment>{c.comment_text}</TextComment>
                    <StarContainer>
                      <RenderStars stars={c.stars} />
                    </StarContainer>
                  </StyledComments>
                ))}
              </StyledCommentsContainer>
            </CompetenceArea>
          </StyledWebViewCardExp>
        </StyledPortfolioSectionContainer>
      </StyledRightSideBarContainer>

      {editModalOpen && (
        <EditFormCandidatProfil
          data={data}
          editModalOpen={editModalOpen}
          onSaveChanges={handleSaveChanges}
          onClose={closeEditModal}
        />
      )}

      {editModalProfilePictureOpen && (
        <EditImageProfilCandidat
          data={data}
          editModalProfilePictureOpen={editModalProfilePictureOpen}
          onSaveChanges={handleSaveChangesProfileImage}
          onClose={closeEditModalImage}
        />
      )}

      {editModalProfileCVOpen && (
        <EditCVProfileCandidat
          data={data}
          editModalProfileCVOpen={editModalProfileCVOpen}
          onSaveChanges={handleSaveChangesProfileCV}
          onClose={closeEditModalCV}
        />
      )}
    </StyledViewCandidatProfil>
  );
};

export default ViewCandidatProfil;
