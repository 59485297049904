import FormationsCard from "components/DashboardHome/Formations/FormationsCard/FormationsCard";
import DashboardItGalaxyMarketplacePurchased from "components/DashboardItGalaxyMarketplaceComponnent/DashboardItGalaxyMarketplacePurchased/DashboardItGalaxyMarketplacePurchased";
import GenericInput from "components/Inputs/GenericInput/GenericInput";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { languagesData } from "../../../data/languagesData";
import { skillsData } from "../../../data/skillData";
import { useGetMarketplaceItgalaxyQuery } from "../../../redux/api/marketplaceItgalaxy/marketplaceItgalaxyApi";
import { formConfig } from "./MarketplaceComponnents.config";
import {
  Container,
  ContainerWrapper,
  NewProductButtonContainer,
  NotFoundTextStyle,
  ProductLabelsContainer,
  RowStyle,
  SearchBar,
  StyleBarSearch,
  StyleBarSearchSkills,
  StyleCount,
  StyleCount1,
  StyleLineCount,
  StyleProductsCount,
  TopContainer,
  ViewLabelMyProducts,
  ViewLabelProducts,
  Wrapper,
} from "./MarketplaceComponnents.style";
import Header from "components/Header/Header";
import Register from "components/Authentification/modals/register";

const MarketplaceComponents = (props) => {
  const navigate = useNavigate();
  const { skill, language } = useParams();
  const [animateDirection, setAnimateDirection] = useState("inRight");
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(2000000);
  const [searchQuery, setSearchQuery] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [purchasedProducts, setPurchasedProducts] = useState(false);
  const [showModalRegister, setModalRegister] = useState(false);

  const location = useLocation();

  const {
    data: productsData,
    error: productsDataError,
    isLoading: productsDataLoading,
  } = useGetMarketplaceItgalaxyQuery(
    `?page=${currentPage}&limit=${recordsPerPage}&userId=${props.id}&userType=${props.role}${searchQuery}`
  );

  // Validate URL parameters and set initial search query
  useEffect(() => {
    let shouldRedirect = false;
    let newSearchQuery = "";

    if (skill) {
      const formattedSkill = skill.replace(/-/g, ' ');
      const validSkill = skillsData.find(
        s => s.title.toLowerCase() === formattedSkill.toLowerCase()
      );

      if (validSkill) {
        newSearchQuery = `&skills=${validSkill.title}`;
      } else {
        shouldRedirect = true;
      }
    }

    if (language) {
      const formattedLanguage = language.replace(/-/g, ' ');
      const validLanguage = languagesData.find(
        l => l.title.toLowerCase() === formattedLanguage.toLowerCase()
      );

      if (validLanguage) {
        newSearchQuery = `&languages=${validLanguage.title}`;
      } else {
        shouldRedirect = true;
      }
    }

    if (shouldRedirect) {
      navigate('/search/products');
    } else if (newSearchQuery) {
      setSearchQuery(newSearchQuery);
    }
  }, [skill, language, navigate]);

  const handleChangeSearch = useCallback((e) => {
    if (e.key === "Enter") {
      const queryParams = e.target.value
        ? `&description=${e.target.value}`
        : "";
      setSearchQuery(queryParams);
    }
  }, []);

  const handleModalRegister = () => {
    setModalRegister(true);
  };

  const handleCloseModalRegister = () => {
    setModalRegister(false);
  };


  const handleChangeSkills = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    const queryParams = `&skills=${selectedValues}`;
    setSearchQuery(queryParams);
  };

  const handleChangeLanguages = (selectedOptions) => {
    const selectedValues = selectedOptions.map((option) => option.value);
    const queryParams = `&languages=${selectedValues}`;
    setSearchQuery(queryParams);
  };



  useEffect(() => {
    if (!productsDataLoading && !productsDataError && productsData) {
      const marketplaceProducts = productsData?.data?.marketplaceProducts || [];
      const applicationProducts = productsData?.data?.applicationProducts || [];
      setAllProducts([...marketplaceProducts, ...applicationProducts]); // Spread syntax combines both arrays
    }
  }, [productsData, productsDataLoading, productsDataError]);

  const handlePurchasedProducts = () => {
    setPurchasedProducts(true);
  };

  const handleCreateProduct = () => {
    props.setTrainerForm(true);
  };

  const formMethods = useForm({});
  const type = formMethods.watch("type");

  const handleNavigate = (type, title, token) => {

    if (location.pathname === '/search/products') {
      handleModalRegister();

    } else {
      if (type === "MARKETPLACE") {
        if (title === "Server Ubuntu") {
          window.location.href = `/createProductServer?type=ubuntu`;
        } else if (title === "Server Debian") {
          window.location.href = `/createProductServer?type=debian`;
        } else if (title === "Server RedHat") {
          window.location.href = `/createProductServer?type=redhat`;
        } else if (title === "Database Postgres") {
          window.location.href = `/createProductDatabase?type=postgres`;
        } else if (title === "Database Mongodb") {
          window.location.href = `/createProductDatabase?type=mongodb`;
        } else if (title === "Database Mysql") {
          window.location.href = `/createProductDatabase?type=mysql`;
        }
      } else if (type === "APPLICATION") {
        window.location.href = `/itgalaxyProductDetails/${token}`;
      }
    }
  };

  if (productsDataLoading) {
    return <div>Loading...</div>;
  }

  // if (productsDataError) {
  //   return <div>Error loading data: {productsDataError.message}</div>;
  // }

  return !purchasedProducts ? (
    <Wrapper>
      {location.pathname === '/search/products' && (
        <Header active={"FREELANCERS"} />
      )}
      <ContainerWrapper>
        <SearchBar>
          <FormProvider {...formMethods}>
            <StyleBarSearch onKeyDown={handleChangeSearch}>
              <GenericInput
                inputObject={{
                  ...formConfig.title,
                  label: "filterType",
                }}
                onChange={(e) => handleChangeSearch(e.target.value)}
              />
            </StyleBarSearch>
            {!skill && (
              <StyleBarSearchSkills>
                <GenericInput
                  inputObject={{
                    ...formConfig.skills,
                    label: "filterType",
                  }}
                  onChange={(e) => handleChangeSkills(e)}
                />
              </StyleBarSearchSkills>
            )}
            {!language && (
              <StyleBarSearchSkills>
                <GenericInput
                  inputObject={{
                    ...formConfig.languages,
                    label: "filterType",
                  }}
                  onChange={(e) => handleChangeLanguages(e)}
                />
              </StyleBarSearchSkills>
            )}

            {props.role === "CANDIDAT" && (
              <NewProductButtonContainer onClick={handleCreateProduct}>
                {"Create a product"}
              </NewProductButtonContainer>
            )}
          </FormProvider>
        </SearchBar>
        <TopContainer>
          <Container>
            <StyleLineCount>
              <ProductLabelsContainer>
                <ViewLabelProducts>Marketplace Products</ViewLabelProducts>
                {location.pathname !== '/search/products' && (

                  <ViewLabelMyProducts onClick={handlePurchasedProducts}>
                    Purchased Products
                  </ViewLabelMyProducts>)}
              </ProductLabelsContainer>
              <StyleProductsCount>
                {productsData?.pagination && (
                  <StyleCount1>
                    <StyleCount>{productsData.pagination.totals}</StyleCount>{" "}
                    products found
                  </StyleCount1>
                )}
              </StyleProductsCount>
            </StyleLineCount>
          </Container>
          {allProducts?.length > 0 ? (
            <RowStyle spacing={3}>
              {allProducts.map((value) => (
                <FormationsCard
                  key={value.id}
                  formation={value}
                  animateDirection={animateDirection}
                  category={type}
                  onClick={() =>
                    handleNavigate(value.type, value.title, value.display)
                  }
                />
              ))}
            </RowStyle>
          ) : (
            <NotFoundTextStyle>
              Change the criteria to find more trainings ...
            </NotFoundTextStyle>
          )}
        </TopContainer>
      </ContainerWrapper>
      {showModalRegister && (
        <Register
          openModalRegister={showModalRegister}
          setOpenModalRegister={setModalRegister}
          handleModalRegister={handleCloseModalRegister}
          proxy={"dashboard"}
        />)}
    </Wrapper>
  ) : (
    <DashboardItGalaxyMarketplacePurchased
      setPurchasedProducts={setPurchasedProducts}
      role={props.role}
    />
  );
};

export default MarketplaceComponents;
