export const skillsData = [
  {
    id: "1",
    title: "HTML",
  },
  {
    id: "2",
    title: "CSS",
  },
  {
    id: "3",
    title: "CSS tools",
  },
  {
    id: "4",
    title: "Photoshop",
  },
  {
    id: "5",
    title: "JavaScript",
  },
  {
    id: "6",
    title: "Java",
  },
  {
    id: "7",
    title: "JavaFX",
  },
  {
    id: "8",
    title: "NodeJS",
  },
  {
    id: "9",
    title: "Go",
  },
  {
    id: "10",
    title: "Python",
  },
  {
    id: "11",
    title: "NumPy",
  },
  {
    id: "12",
    title: "Scrum",
  },
  {
    id: "13",
    title: "Coaching",
  },
  {
    id: "14",
    title: "Machine Learning",
  },
  {
    id: "15",
    title: "Design",
  },
  {
    id: "16",
    title: "React",
  },
  {
    id: "17",
    title: "Search Engine Optimization",
  },
  {
    id: "18",
    title: "Project Coordination",
  },
  {
    id: "19",
    title: "Stakeholder Engagement",
  },
  {
    id: "20",
    title: "Microsoft Excel",
  },
  {
    id: "21",
    title: "Microsoft Outlook",
  },
  {
    id: "22",
    title: "Microsoft Word",
  },
  {
    id: "23",
    title: "Commercial Awareness",
  },
  {
    id: "24",
    title: "Robot Framework",
  },
  {
    id: "25",
    title: "Scaled Agile Framework",
  },
  {
    id: "26",
    title: "Test-driven development",
  },
  {
    id: "27",
    title: "Conflict resolution",
  },
  {
    id: "28",
    title: "Negotiation",
  },
  {
    id: "29",
    title: "HR Policies",
  },
  {
    id: "30",
    title: "Spring",
  },
  {
    id: "31",
    title: "Git",
  },
  {
    id: "32",
    title: "Docker",
  },
  {
    id: "33",
    title: "Redis",
  },
  {
    id: "34",
    title: "Jira",
  },
  {
    id: "35",
    title: "PostgreSQL",
  },
  {
    id: "36",
    title: "Facilitation",
  },
  {
    id: "37",
    title: "Building Trust",
  },
  {
    id: "38",
    title: "Professional Networking",
  },
  {
    id: "39",
    title: "Identifying External Influences",
  },
  {
    id: "40",
    title: "Managing Talent",
  },
  {
    id: "41",
    title: "Creating Company Culture",
  },
  {
    id: "42",
    title: "Effective Listening",
  },
  {
    id: "43",
    title: "Being Approachable & Accessible",
  },
  {
    id: "44",
    title: "Change Management",
  },
  {
    id: "45",
    title: "Persuasion",
  },
  {
    id: "46",
    title: "Negotiating",
  },
  {
    id: "47",
    title: "Problem-Solving",
  },
  {
    id: "48",
    title: "Training",
  },
  {
    id: "49",
    title: "Consulting",
  },
  {
    id: "50",
    title: "Angular",
  },
  {
    id: "51",
    title: "Vue",
  },
  {
    id: "52",
    title: "Express",
  },
  {
    id: "53",
    title: "Laravel",
  },
  {
    id: "54",
    title: "Php",
  },
  {
    id: "55",
    title: "Typescript",
  },
  {
    id: "56",
    title: "Symfony",
  },
  {
    id: "57",
    title: "C",
  },
  {
    id: "58",
    title: "C++",
  },
  {
    id: "59",
    title: "ReactJs",
  },
  {
    id: "60",
    title: "Kubernetes",
  },
  {
    id: "61",
    title: "GitHub Actions",
  },
  {
    id: "62",
    title: "WordPress",
  },
  {
    id: "63",
    title: "React Native",
  },
  {
    id: "64",
    title: "Bootstrap",
  },
  {
    id: "65",
    title: "Tailwind CSS",
  },
  {
    id: "66",
    title: "MySQL",
  },
  {
    id: "67",
    title: "MongoDB",
  },
  {
    id: "68",
    title: "UML",
  },
  {
    id: "69",
    title: "GitHub",
  },
  {
    id: "70",
    title: "GitLab",
  },
  {
    id: "71",
    title: "Postman (REST API)",
  },
  {
    id: "72",
    title: "Trello",
  },
  {
    id: "73",
    title: "Unit Testing",
  },
  {
    id: "74",
    title: "Spring Boot",
  },
  {
    id: "75",
    title: "XML",
  },
  {
    id: "76",
    title: "JSX",
  },
  {
    id: "77",
    title: "CI/CD",
  },
  {
    id: "78",
    title: "Jenkins",
  },
  {
    id: "79",
    title: "Flutter",
  },
  {
    id: "80",
    title: "Power BI",
  },
  {
    id: "81",
    title: "Azure DevOps",
  },
  {
    id: "82",
    title: "Vue.js",
  },
  {
    id: "83",
    title: "Agile Methodologies",
  },
  {
    id: "84",
    title: "UML Modeling",
  },
  {
    id: "85",
    title: "Spring",
  },
  {
    id: "86",
    title: "Next.js",
  },
  {
    id: "87",
    title: "Azure",
  },
  {
    id: "88",
    title: "Figma",
  },
  {
    id: "89",
    title: "DevOps",
  },
  {
    id: "90",
    title: "Linux",
  },
  {
    id: "91",
    title: "ExpressJS",
  },
  {
    id: "92",
    title: "Apache",
  },
  {
    id: "93",
    title: "JS",
  },
  {
    id: "94",
    title: "PHP",
  },
  {
    id: "95",
    title: "Kotlin",
  },
  {
    id: "95",
    title: "Wordpress",
  },
  {
    id: "95",
    title: "Shopify",
  },
  {
    id: "95",
    title: "SEO",
  },
  {
    id: "95",
    title: "Backlinks",
  },
  {
    id: "95",
    title: "Audit SEO",
  },

  
];