import styled from "styled-components";

export const TextStyle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.94px;
  text-align: left;
  margin-bottom: 0px;
  color: #969696;
`;

export const NumberStyle = styled.p`
  font-family: Inter, sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: left;
  margin-bottom: 0px;
  color: #2684ff;
`;
