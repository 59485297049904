import { Link } from "react-router-dom"; // If you want to link back to your home page or other sections

const UserAgreement = () => {
  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto" }}>
      <h1 style={{ textAlign: "center" }}>User Agreement</h1>
      <p>Last updated: 01/10/2024</p>

      <h2>1. Introduction</h2>
      <p>
        This User Agreement ("Agreement") is a legal contract between you
        ("User") and ItGalaxy ("us", "we", or "our"). By accessing or using our
        services, you agree to comply with and be bound by the terms of this
        Agreement.
      </p>

      <h2>2. Acceptance of Terms</h2>
      <p>
        By creating an account or using the Service, you affirm that you are of
        legal age to enter into this Agreement and that you accept the terms and
        conditions set forth herein.
      </p>

      <h2>3. Service Description</h2>
      <p>
        ItGalaxy provides a platform for [describe services/products offered by
        ItGalaxy]. We strive to maintain the platform’s functionality, security,
        and user experience.
      </p>

      <h2>4. User Responsibilities</h2>
      <p>As a User, you agree to adhere to the following responsibilities:</p>
      <ul>
        <li>
          Provide accurate, up-to-date, and complete information when
          registering.
        </li>
        <li>
          Maintain the security and confidentiality of your account information.
        </li>
        <li>
          Use the Service in compliance with all applicable laws and
          regulations.
        </li>
        <li>
          Respect the rights of other users and refrain from malicious
          activities.
        </li>
      </ul>

      <h2>5. Termination</h2>
      <p>
        We reserve the right to suspend or terminate your account and access to
        the Service if you violate this Agreement or engage in any fraudulent,
        abusive, or unlawful activity.
      </p>

      <h2>6. Content Ownership and Rights</h2>
      <p>
        All content generated and displayed within the Service remains the
        property of ItGalaxy or our licensors. You are granted a limited,
        non-exclusive, non-transferable license to use the Service for personal
        and internal use only.
      </p>

      <h2>7. Limitation of Liability</h2>
      <p>
        To the extent permitted by law, ItGalaxy will not be liable for any
        indirect, incidental, special, or consequential damages arising from, or
        related to, your use of the Service, including but not limited to loss
        of data or profits.
      </p>

      <h2>8. Indemnification</h2>
      <p>
        You agree to indemnify and hold harmless ItGalaxy, its affiliates, and
        their respective employees, officers, and directors from any claims,
        losses, liabilities, damages, or expenses (including reasonable
        attorneys’ fees) arising out of your use of the Service or violation of
        this Agreement.
      </p>

      <h2>9. Modifications to the Agreement</h2>
      <p>
        ItGalaxy reserves the right to modify this Agreement at any time. Any
        changes will be effective immediately upon posting the updated Agreement
        on our website. Your continued use of the Service after modifications
        indicates your acceptance of the new terms.
      </p>

      <h2>10. Governing Law</h2>
      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of [Your Country/State], without regard to its conflict of law
        principles.
      </p>

      <h2>11. Contact Us</h2>
      <p>
        If you have any questions about this User Agreement, please contact us
        at support@itgalaxy.com.
      </p>

      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Link to="/">Go to Home Page</Link>
      </div>
    </div>
  );
};
export default UserAgreement;
