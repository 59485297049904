import ImageProfilCard from "components/ImageProfilCard/ImageProfilCard";
import RenderStars from "components/RenderStars/RenderStars";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useGetCommentsQuery } from "../../../../redux/api/comments/commentsApi";
import { StyledLoaderContainer } from "../../styeldComponants";
import EditFormEntrepriseProfil from "./EditFormEntrepriseProfil";
import EditImageProfilEntreprise from "./EditImageProfilEntreprise";
import {
  CommentUser,
  CommentedStyle,
  CompetenceArea,
  FullScreenIcon,
  StarContainer,
  StyleWithIcon,
  StyledAboutMeCard,
  StyledAboutMeText,
  StyledAvailableText,
  StyledBlackBackground,
  StyledComments,
  StyledCommentsContainer,
  StyledDot,
  StyledEditImageIcon,
  StyledEditProfileButton,
  StyledLeftSideBar,
  StyledLeftSideBarAvailable,
  StyledLeftSideBarContainer,
  StyledLeftSideBarImage,
  StyledLeftSideBarLightParagraph,
  StyledLeftSideBarLocationContainer,
  StyledLeftSideBarName,
  StyledLeftSideBarNameStyle,
  StyledLeftSideBarUSerOtherInfo,
  StyledLink,
  StyledPortfolioSectionContainer,
  StyledReadMoreButton,
  StyledRightSideBarContainer,
  StyledSideBarHeader,
  StyledSidebarContent,
  StyledViewEntrepriseProfil,
  StyledWebViewCard,
  TextComment,
  TitleProfile,
} from "./styled";
import { MapPin , Pencil} from 'lucide-react'; // Import the MapPin icon from Lucide

const ViewEntrepriseProfil = ({ data, setDataUser, editProfil }) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isFullScreenExp, setIsFullScreenExp] = useState(false);
  const [editModalProfilePictureOpen, setEditModalProfilePictureOpen] =
    useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const params = "?CommentedType=ENTREPRISE&commentedId=" + data.id;
  const { data: comments, error, isLoading } = useGetCommentsQuery(params);


  if (!data) {
    return (
      <StyledLoaderContainer>
        <Spinner style={{ width: "100px", height: "100px" }} />
      </StyledLoaderContainer>
    );
  }

  const toggleFullScreenExp = () => setIsFullScreenExp((prev) => !prev);
  const toggleReadMore = () => setIsExpanded((prev) => !prev);

  const handleModalOpen = (setOpenFunc) => () => setOpenFunc(true);
  const handleModalClose = (setOpenFunc) => () => setOpenFunc(false);

  const handleSaveChanges = (updatedData) => {
    setDataUser((prevData) => ({ ...prevData, ...updatedData }));
    handleModalClose(setEditModalOpen)();
  };

  const handleSaveChangesProfileImage = (updatedData) => {
    setDataUser((prevData) => ({ ...prevData, ...updatedData }));
    handleModalClose(setEditModalProfilePictureOpen)();
  };

  const showReadMore = data?.entreprise_description?.length > 300;
  return (
    <StyledViewEntrepriseProfil >
      <StyledLeftSideBar isFullScreenExp={isFullScreenExp}>
        <StyledSideBarHeader>
          <StyledBlackBackground />
          <StyledSidebarContent>
            <StyledLeftSideBarImage style={{ position: "relative" }}>
              <ImageProfilCard
                id={data.id}
                type={"entreprises"}
                typeimg={"profil"}
              />
              {editProfil && (
                <StyledEditImageIcon
                  onClick={handleModalOpen(setEditModalProfilePictureOpen)}
                >
                  <Pencil size={16} color="black" />
                </StyledEditImageIcon>
              )}
            </StyledLeftSideBarImage>
          </StyledSidebarContent>
        </StyledSideBarHeader>

        <StyledLeftSideBarName>
          <StyledLeftSideBarNameStyle>
            {data.username}{" "}
          </StyledLeftSideBarNameStyle>
        </StyledLeftSideBarName>

        <StyledLeftSideBarAvailable>
          <StyledDot isActive={data.status === "ACTIVE"} />
          <StyledAvailableText>
            {data.status === "ACTIVE" ? "AVAILABLE" : "UNAVAILABLE"}
          </StyledAvailableText>
        </StyledLeftSideBarAvailable>

        <StyledLeftSideBarUSerOtherInfo>
          {editProfil && (
            <StyledLeftSideBarLightParagraph>
              {data.email}
            </StyledLeftSideBarLightParagraph>
          )}

          <StyledLeftSideBarLocationContainer>
          <MapPin size={20} style={{ paddingRight: "10px" }} /> {/* Using Lucide MapPin */}            <StyledLeftSideBarLightParagraph>
              {data.country_details}
            </StyledLeftSideBarLightParagraph>
          </StyledLeftSideBarLocationContainer>
        </StyledLeftSideBarUSerOtherInfo>

        <StyledLeftSideBarContainer>
        <StyledLink
            href={
              data?.website?.startsWith('http://') || data?.website?.startsWith('https://')
                ? data?.website
                : `https://${data?.website}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>
              <i className="fas fa-globe" style={{ paddingRight: "10px" }}></i>
              {data.website}
            </span>
            <i className="fas fa-external-link-alt"></i>
          </StyledLink>

          <hr style={{ borderTop: "1px solid", opacity: "1", width: "100%" }} />

          {editProfil && (
            <StyledEditProfileButton
              onClick={handleModalOpen(setEditModalOpen)}
            >
               Edit your profile  <Pencil size={16} color="black" />

            </StyledEditProfileButton>
          )}
        </StyledLeftSideBarContainer>
      </StyledLeftSideBar>

      <StyledRightSideBarContainer>
        <StyledAboutMeCard isFullScreenExp={isFullScreenExp}>
          <TitleProfile>About Me</TitleProfile>
          <StyledAboutMeText isExpanded={isExpanded}>
            {isExpanded
              ? data.entreprise_description
              : data.entreprise_description
              ? `${data.entreprise_description.slice(0, 200)}${
                  data.entreprise_description.length > 200 ? "..." : ""
                }`
              : "No description available."}
          </StyledAboutMeText>

          {showReadMore && (
            <StyledReadMoreButton onClick={toggleReadMore}>
              {isExpanded ? "Read Less" : "Read More"}
              <i className={`fas fa-chevron-${isExpanded ? "up" : "down"}`} />
            </StyledReadMoreButton>
          )}
        </StyledAboutMeCard>

        <StyledPortfolioSectionContainer>
          <StyledWebViewCard className={isFullScreenExp ? "full-screen" : ""}>
            <StyleWithIcon>
              <TitleProfile>ItGalaxy Experiences</TitleProfile>
              <FullScreenIcon
                className={`fas ${
                  isFullScreenExp ? "fa-compress" : "fa-expand"
                }`}
                onClick={toggleFullScreenExp}
              />
            </StyleWithIcon>
            <CompetenceArea>
              <StyledCommentsContainer>
                {comments?.data?.length > 0 ? ( // Check if there are any comments
                  comments?.data?.map((c) => (
                    <StyledComments key={c.id}>
                      <CommentedStyle>
                        <CommentUser>
                          {c?.project?.title} -{" "}
                          {new Date(c.createdAt).toLocaleDateString()}
                        </CommentUser>
                        <ImageProfilCard
                          id={c.userId}
                          type={"candidats"}
                          typeimg={"cercel"}
                          src={c.userProfileImageUrl} // Add a path for user profile image URL
                          alt={`${c.userId}'s profile`} // Alt text for the image
                        />
                        <span>{c?.project?.price} euro</span>
                      </CommentedStyle>
                      <TextComment>{c.comment_text}</TextComment>
                      <StarContainer>
                        <RenderStars stars={c.stars} />{" "}
                        {/* Render stars for this comment */}
                      </StarContainer>
                    </StyledComments>
                  ))
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      color: "#666",
                      margin: "20px 0",
                      height: "100%",
                    }}
                  >
                    <p>No comments or experiences yet.</p>
                  </div>
                )}
              </StyledCommentsContainer>
            </CompetenceArea>
          </StyledWebViewCard>
        </StyledPortfolioSectionContainer>
      </StyledRightSideBarContainer>

      {editModalOpen && (
        <EditFormEntrepriseProfil
          data={data}
          editModalOpen={editModalOpen}
          onSaveChanges={handleSaveChanges}
          onClose={handleModalClose(setEditModalOpen)}
        />
      )}

      {editModalProfilePictureOpen && (
        <EditImageProfilEntreprise
          data={data}
          editModalProfilePictureOpen={editModalProfilePictureOpen}
          onSaveChanges={handleSaveChangesProfileImage}
          onClose={handleModalClose(setEditModalProfilePictureOpen)}
        />
      )}
    </StyledViewEntrepriseProfil>
  );
};
export default ViewEntrepriseProfil;
