import { InputTypes } from "../../../Inputs/input.type";

export const formConfig = {
  price: {
    inputType: InputTypes.NUMBER,
    fieldName: "price",
    config: {
      required: true,
    },
  },
};
