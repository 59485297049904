import { Modal } from "react-bootstrap"; // Ensure you have react-bootstrap installed
import {
  OptionCard,
  OptionContainer,
  StyledModalBody,
} from "./ModalShowChoiceCompany.style";

const ModalShowChoiceCompany = (props) => {
  const handleChoice = (choice) => {
    console.log(`You selected: ${choice}`);

    if (choice === "Compose Your Team") {
      window.location.href = `/projects?composeyourTeam=true`;
    }
    if (choice === "Share Your Project") {
      window.location.href = `/projects?shareTasks=true`;
    }
    if (choice === "Products") {
      window.location.href = `/videosTrainings`;
    }
    if (choice === "ITgalaxy Marketplace") {
      window.location.href = `/marketplace`;
    }
  };

  return (
    <Modal show={props.show} onHide={props.onHide} centered={true} size="lg">
      <StyledModalBody>
        <OptionContainer>
          <OptionCard onClick={() => handleChoice("Compose Your Team")}>
            <h3>Compose Your Team</h3>
          </OptionCard>
          <OptionCard onClick={() => handleChoice("Share Your Project")}>
            <h3>Share Your Project</h3>
          </OptionCard>
          <OptionCard onClick={() => handleChoice("Products")}>
            <h3>Products</h3>
          </OptionCard>
          <OptionCard onClick={() => handleChoice("ITgalaxy Marketplace")}>
            <h3>ItGalaxy Marketplace</h3>
          </OptionCard>
        </OptionContainer>
      </StyledModalBody>
    </Modal>
  );
};

export default ModalShowChoiceCompany;
